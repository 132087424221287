import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  Svg,
  Path,
} from '@react-pdf/renderer';
import { colors } from '../../theme';
import { EncounterDTO } from '@aster/app/core/shared/dtos/encounter';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { VitalsDTO } from '@aster/app/core/shared/dtos/vitals';
import dayjs from 'dayjs';
import FigtreeLight from '../../../src/fonts/Figtree/Figtree-Light.ttf';
import FigtreeRegular from '../../../src/fonts/Figtree/Figtree-Regular.ttf';
import FigtreeMedium from '../../../src/fonts/Figtree/Figtree-Medium.ttf';
import FigtreeBold from '../../../src/fonts/Figtree/Figtree-Bold.ttf';
import FigtreeSemiBold from '../../../src/fonts/Figtree/Figtree-SemiBold.ttf';
import FigtreeExtraBold from '../../../src/fonts/Figtree/Figtree-ExtraBold.ttf';
import FigtreeBlack from '../../../src/fonts/Figtree/Figtree-Black.ttf';
import { names } from '@aster/shared/utils';
import { chunkString } from '../patients/utils/chunkString';
import Html from 'react-pdf-html';

type PrenatalExamination = Record<string, string>;

type FlagType = {
  checked: boolean;
  text: string;
  value: string;
};

Font.register({
  family: 'Figtree',
  fonts: [
    { src: FigtreeLight, fontStyle: 'normal', fontWeight: 300 },
    { src: FigtreeRegular, fontStyle: 'normal', fontWeight: 400 },
    { src: FigtreeMedium, fontStyle: 'normal', fontWeight: 500 },
    { src: FigtreeSemiBold, fontStyle: 'normal', fontWeight: 600 },
    { src: FigtreeBold, fontStyle: 'normal', fontWeight: 700 },
    { src: FigtreeExtraBold, fontStyle: 'normal', fontWeight: 800 },
    { src: FigtreeBlack, fontStyle: 'normal', fontWeight: 900 },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Figtree',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 45,
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: 10,
    paddingBottom: 10,
  },
  container: {
    display: 'flex',
    marginBottom: 10,
    gap: 10,
  },
  pdfHeaderText: {
    fontSize: 20,
    marginBottom: 20,
    fontWeight: 600,
  },
  sectionHeaderText: {
    fontSize: 12,
    color: colors.black,
    fontWeight: 600,
    marginBottom: 10,
  },
  textRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    border: `1px solid ${colors.grayLight}`,
  },
  textColumnView: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  patientTable: {
    borderTop: `1px solid ${colors.grayLight}`,
    borderLeft: `1px solid ${colors.grayLight}`,
  },
  rowView: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${colors.grayLight}`,
    borderRight: `1px solid ${colors.grayLight}`,
    textAlign: 'center',
    alignItems: 'center',
  },
  patientTableTitle: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 6,
  },
  cellRowHeaderText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '100%',
    textAlign: 'left',
    fontSize: 10,
    color: colors.black,
    backgroundColor: colors.grayBackground,
    borderRight: `1px solid ${colors.grayLight}`,
    fontWeight: 600,
    paddingLeft: 5,
    paddingVertical: 5,
  },
  cellRowDataText: {
    paddingVertical: 5,
    width: '50%',
    textAlign: 'left',
    fontSize: 10,
    color: colors.black,
    backgroundColor: colors.white,
    paddingLeft: 5,
  },
  vitalsRowHeaderText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'left',
    fontSize: 10,
    color: colors.black,
    backgroundColor: colors.grayBackground,
    borderLeft: `1px solid ${colors.grayLight}`,
    fontWeight: 600,
    paddingLeft: 5,
    paddingVertical: 5,
  },
  vitalsDateCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 125,
    width: '100%',
    height: '100%',
    textAlign: 'left',
    fontSize: 10,
    color: colors.black,
    backgroundColor: colors.grayBackground,
    fontWeight: 600,
    paddingLeft: 5,
    paddingVertical: 5,
  },
  vitalsDataCell: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    paddingVertical: 5,
    flex: 1,
    textAlign: 'left',
    fontSize: 10,
    color: colors.black,
    backgroundColor: colors.white,
    borderLeft: `1px solid ${colors.grayLight}`,
    paddingLeft: 5,
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 10,
    marginTop: 30,
  },
  note: {
    width: 250,
    marginBottom: 10,
  },
  fullWidthNote: {
    gap: 10,
    width: '100%',
    marginBottom: 10,
  },
  textContentBody: {
    color: colors.black,
    fontSize: 10,
    marginBottom: 4,
  },

  textContentSubHeader: {
    color: colors.black,
    fontSize: 10,
    marginBottom: 4,
    textDecoration: 'underline',
  },
  flaggedText: {
    color: colors.black,
    fontSize: 10,
    marginVertical: 2,
    listStyleType: 'bullet',
  },
});

const GenerateEncountersPdf = ({
  encounters,
  patientProfile,
  vitals,
}: {
  encounters: EncounterDTO[];
  patientProfile: PatientProfileForEncounterDTO;
  vitals: VitalsDTO[];
}) => {
  return (
    <Document>
      {encounters.map((encounter, encounterIndex) => {
        const content = Object.entries(encounter.content);
        return (
          <Page key={encounter.id} size="A4" style={styles.page}>
            <Text style={styles.pdfHeaderText}>Encounter Notes</Text>

            <View
              style={{ display: 'flex', flexDirection: 'row', columnGap: 6 }}
            >
              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.sectionHeaderText}>
                  Patient Information
                </Text>
                <View style={styles.patientTable}>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Name </Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.name}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>DOB</Text>
                    <Text style={styles.cellRowDataText}>
                      {dayjs(patientProfile.dateOfBirth).format('MM/DD/YYYY')}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Address</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.streetAddress1}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>City</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.city}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>State</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.state}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Zip Code</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.zipCode}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Phone</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.phoneNumber}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>MRN</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.mrn}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Age</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.age}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>EDD</Text>
                    <Text style={styles.cellRowDataText}>
                      {patientProfile.calculatedDueDate}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ width: '50%', display: 'flex' }}>
                <Text style={styles.sectionHeaderText}>
                  Encounter Information
                </Text>
                <View style={styles.patientTable}>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Practice Name </Text>
                    <Text style={styles.cellRowDataText}>
                      {encounter.practiceName}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Provider Name </Text>
                    <Text style={styles.cellRowDataText}>
                      {encounter.ownerName}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Start </Text>
                    <Text style={styles.cellRowDataText}>
                      {dayjs(encounter.startTime).format('MM/DD/YYYY h:mm A')}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Encounter Type</Text>
                    <Text style={styles.cellRowDataText}>
                      {names(encounter.templateType).sentenceCase}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Status</Text>
                    <Text style={styles.cellRowDataText}>
                      {encounter.signedBy ? 'Signed' : 'Draft'}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>Signed By </Text>
                    <Text style={styles.cellRowDataText}>
                      {encounter.signedByName ?? 'N/A'}
                    </Text>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>CPT Codes</Text>
                    <View style={styles.textColumnView}>
                      {encounter.cptCodes.map((cptCode) =>
                        chunkString(cptCode, 15).map((chunk) => (
                          <Text style={styles.cellRowDataText}>{chunk}</Text>
                        ))
                      )}
                    </View>
                  </View>
                  <View style={styles.rowView}>
                    <Text style={styles.cellRowHeaderText}>IDC Codes</Text>
                    <View style={styles.textColumnView}>
                      {encounter.icdCodes.map((icdCode) =>
                        chunkString(icdCode, 15).map((chunk) => (
                          <Text style={styles.cellRowDataText}>{chunk}</Text>
                        ))
                      )}
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
              }}
            >
              <Text style={styles.sectionHeaderText}>Vitals</Text>
              <View style={styles.patientTable}>
                <View style={styles.rowView}>
                  <Text style={styles.vitalsDateCell}> </Text>
                  <Text style={styles.vitalsRowHeaderText}>BP</Text>
                  <Text style={styles.vitalsRowHeaderText}>HR</Text>
                  <Text style={styles.vitalsRowHeaderText}>Temp</Text>
                  <Text style={styles.vitalsRowHeaderText}>BG</Text>
                  <Text style={styles.vitalsRowHeaderText}>Height</Text>
                  <Text style={styles.vitalsRowHeaderText}>Weight</Text>
                  <Text style={styles.vitalsRowHeaderText}>SpO2</Text>
                  <Text style={styles.vitalsRowHeaderText}>BMI</Text>
                </View>
                {vitals.map((vital, i) => (
                  <View style={styles.rowView}>
                    <Text style={styles.vitalsDateCell}>
                      {dayjs(vital.timeCreated).format('MM/DD/YYYY h:mm A')}
                    </Text>

                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.bloodPressure ?? ''), 6).map(
                        (bp) => (
                          <Text>{bp}</Text>
                        )
                      )}
                    </View>

                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.heartRate ?? ''), 6).map(
                        (hr) => (
                          <Text>{hr}</Text>
                        )
                      )}
                    </View>
                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.temperature ?? ''), 6).map(
                        (temp) => (
                          <Text>{temp}</Text>
                        )
                      )}
                    </View>
                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.bloodGlucose ?? ''), 6).map(
                        (bg) => (
                          <Text>{bg}</Text>
                        )
                      )}
                    </View>
                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.height ?? ''), 6).map(
                        (height) => (
                          <Text>{height}</Text>
                        )
                      )}
                    </View>
                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.weight ?? ''), 6).map(
                        (weight) => (
                          <Text>{weight}</Text>
                        )
                      )}
                    </View>
                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.spo2 ?? ''), 6).map((spo2) => (
                        <Text>{spo2}</Text>
                      ))}
                    </View>
                    <View style={styles.vitalsDataCell}>
                      {chunkString(String(vital.bmi ?? ''), 6).map((bmi) => (
                        <Text>{bmi}</Text>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={styles.noteContainer}>
              {encounterIndex === 0 && (
                <>
                  {patientProfile.calculatedDueDate && (
                    <View style={styles.note}>
                      <Text style={styles.sectionHeaderText}>
                        EDD: {patientProfile.calculatedDueDate}
                      </Text>
                    </View>
                  )}
                  {patientProfile.vaccinationNotes && (
                    <View style={styles.note}>
                      <Text style={styles.sectionHeaderText}>
                        Vaccination Notes:
                      </Text>
                      <Text style={styles.textContentBody}>
                        {patientProfile.vaccinationNotes}
                      </Text>
                    </View>
                  )}
                  {patientProfile.medicationNotes && (
                    <View style={styles.note}>
                      <Text style={styles.sectionHeaderText}>
                        Medication Notes:
                      </Text>
                      <Text style={styles.textContentBody}>
                        {patientProfile.medicationNotes}
                      </Text>
                    </View>
                  )}
                  {patientProfile.additionalProblems && (
                    <View style={styles.note}>
                      <Text style={styles.sectionHeaderText}>
                        Additional Problems:
                      </Text>
                      <Text style={styles.textContentBody}>
                        {patientProfile.additionalProblems}
                      </Text>
                    </View>
                  )}
                </>
              )}
              {content.length > 0 &&
                !['birthflow', 'newborn', 'postpartum'].includes(
                  encounter.templateType
                ) && (
                  <View style={styles.fullWidthNote}>
                    <Text style={styles.sectionHeaderText}>
                      Encounter Notes:
                    </Text>
                    {content.map(
                      ([key, value]: [
                        string,
                        string | FlagType[] | PrenatalExamination
                      ]) => {
                        if (key === 'prenatalExamination') {
                          const exams = Object.entries(
                            value as PrenatalExamination
                          );
                          if (!exams.length) return false;
                          return (
                            <>
                              <Text style={styles.textContentSubHeader}>
                                {names(key).sentenceCase}:
                              </Text>
                              {exams.map(([examKey, examValue]) => (
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Text
                                    style={Object.assign(
                                      {},
                                      {
                                        ...styles.textContentBody,
                                        fontWeight: 600,
                                      }
                                    )}
                                  >
                                    {names(examKey).sentenceCase}:{' '}
                                  </Text>
                                  <Text style={styles.textContentBody}>
                                    {examValue}
                                  </Text>
                                </View>
                              ))}
                            </>
                          );
                        }
                        if (key === 'flags') {
                          const checkedFlags = (value as FlagType[]).filter(
                            (flag) => flag.checked
                          );
                          if (checkedFlags.length > 0) {
                            return (
                              <>
                                <Text style={styles.textContentSubHeader}>
                                  Flagged:
                                </Text>

                                {checkedFlags.map((flag) => (
                                  <Text style={styles.textContentBody}>
                                    {names(flag.value).sentenceCase}
                                  </Text>
                                ))}
                              </>
                            );
                          }
                          return false;
                        }

                        return (
                          <>
                            <Text style={styles.textContentSubHeader}>
                              {names(key).sentenceCase}:
                            </Text>
                            <Html style={styles.textContentBody}>
                              {value as string}
                            </Html>
                          </>
                        );
                      }
                    )}
                  </View>
                )}
              {encounter.templateType === 'birthflow' &&
                encounter.content.notes && (
                  <View style={styles.note}>
                    <Text style={styles.sectionHeaderText}>
                      Birth Flow Notes:
                    </Text>
                    {encounter.content.notes.map((note: any) => (
                      <View style={{ marginTop: 10 }}>
                        <Text style={styles.textContentBody}>
                          {note.timestamp}
                        </Text>
                        <Text style={styles.textContentBody}>
                          {note.content}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}
            </View>
            <Svg
              fixed
              style={{ position: 'absolute', bottom: 10, right: 10 }}
              width="108"
              height="25"
              viewBox="0 0 108 25"
              fill="none"
            >
              <Path
                d="M0.298828 14.94V7.94H2.57883C3.03216 7.94 3.43216 8.03334 3.77883 8.22C4.13216 8.40667 4.4055 8.66667 4.59883 9C4.79883 9.33334 4.89883 9.72 4.89883 10.16C4.89883 10.5867 4.80883 10.9667 4.62883 11.3C4.44883 11.6333 4.20216 11.8967 3.88883 12.09C3.5755 12.2767 3.21216 12.37 2.79883 12.37H1.14883V14.94H0.298828ZM1.14883 11.6H2.79883C3.1655 11.6 3.4655 11.4667 3.69883 11.2C3.93883 10.9267 4.05883 10.58 4.05883 10.16C4.05883 9.72667 3.91883 9.37667 3.63883 9.11C3.35883 8.84334 3.00216 8.71 2.56883 8.71H1.14883V11.6Z"
                fill="#586068"
              />
              <Path
                d="M7.97219 15.06C7.48552 15.06 7.05219 14.95 6.67219 14.73C6.29885 14.5033 6.00552 14.1933 5.79219 13.8C5.57885 13.4067 5.47219 12.9533 5.47219 12.44C5.47219 11.9267 5.57552 11.4733 5.78219 11.08C5.99552 10.6867 6.28885 10.38 6.66219 10.16C7.04219 9.93334 7.47219 9.82 7.95219 9.82C8.44552 9.82 8.87885 9.93334 9.25219 10.16C9.63219 10.38 9.92552 10.6867 10.1322 11.08C10.3455 11.4733 10.4522 11.9267 10.4522 12.44C10.4522 12.9533 10.3455 13.4067 10.1322 13.8C9.92552 14.1933 9.63552 14.5033 9.26219 14.73C8.88885 14.95 8.45885 15.06 7.97219 15.06ZM7.97219 14.32C8.30552 14.32 8.59552 14.24 8.84219 14.08C9.08885 13.92 9.28219 13.7 9.42219 13.42C9.56885 13.14 9.64219 12.8133 9.64219 12.44C9.64219 12.0667 9.56885 11.74 9.42219 11.46C9.28219 11.18 9.08552 10.96 8.83219 10.8C8.57885 10.64 8.28552 10.56 7.95219 10.56C7.62552 10.56 7.33552 10.64 7.08219 10.8C6.83552 10.96 6.63885 11.18 6.49219 11.46C6.35219 11.74 6.28219 12.0667 6.28219 12.44C6.28219 12.8067 6.35219 13.1333 6.49219 13.42C6.63885 13.7 6.83885 13.92 7.09219 14.08C7.35219 14.24 7.64552 14.32 7.97219 14.32Z"
                fill="#586068"
              />
              <Path
                d="M15.976 14.94L17.666 9.94H18.476L16.776 14.94H15.976ZM12.576 14.94L14.266 9.94H15.016L13.346 14.94H12.576ZM12.536 14.94L10.836 9.94H11.646L13.316 14.94H12.536ZM15.976 14.94L14.306 9.94H15.066L16.736 14.94H15.976Z"
                fill="#586068"
              />
              <Path
                d="M21.3506 15.06C20.864 15.06 20.434 14.95 20.0606 14.73C19.6873 14.5033 19.394 14.1933 19.1806 13.8C18.974 13.4067 18.8706 12.9533 18.8706 12.44C18.8706 11.9267 18.974 11.4733 19.1806 11.08C19.394 10.6867 19.684 10.38 20.0506 10.16C20.424 9.93334 20.8506 9.82 21.3306 9.82C21.7973 9.82 22.2006 9.93667 22.5406 10.17C22.8806 10.3967 23.144 10.7167 23.3306 11.13C23.5173 11.5433 23.6106 12.03 23.6106 12.59H19.4806L19.6806 12.42C19.6806 12.82 19.7506 13.1633 19.8906 13.45C20.0373 13.7367 20.2406 13.9567 20.5006 14.11C20.7606 14.2567 21.0573 14.33 21.3906 14.33C21.744 14.33 22.0406 14.2467 22.2806 14.08C22.5273 13.9133 22.7173 13.6933 22.8506 13.42L23.5406 13.77C23.414 14.03 23.244 14.2567 23.0306 14.45C22.824 14.6433 22.5773 14.7933 22.2906 14.9C22.0106 15.0067 21.6973 15.06 21.3506 15.06ZM19.7306 12.08L19.5206 11.92H22.9706L22.7606 12.09C22.7606 11.77 22.6973 11.4933 22.5706 11.26C22.444 11.0267 22.274 10.8467 22.0606 10.72C21.8473 10.5933 21.6006 10.53 21.3206 10.53C21.0473 10.53 20.7873 10.5933 20.5406 10.72C20.3006 10.8467 20.104 11.0267 19.9506 11.26C19.804 11.4867 19.7306 11.76 19.7306 12.08Z"
                fill="#586068"
              />
              <Path
                d="M25.0596 12.23C25.0596 11.69 25.1629 11.2533 25.3696 10.92C25.5763 10.58 25.8396 10.33 26.1596 10.17C26.4863 10.0033 26.8296 9.92 27.1896 9.92V10.67C26.8963 10.67 26.6129 10.72 26.3396 10.82C26.0729 10.9133 25.8529 11.07 25.6796 11.29C25.5063 11.51 25.4196 11.81 25.4196 12.19L25.0596 12.23ZM24.6196 14.94V9.94H25.4196V14.94H24.6196Z"
                fill="#586068"
              />
              <Path
                d="M30.1885 15.06C29.7018 15.06 29.2718 14.95 28.8985 14.73C28.5252 14.5033 28.2318 14.1933 28.0185 13.8C27.8118 13.4067 27.7085 12.9533 27.7085 12.44C27.7085 11.9267 27.8118 11.4733 28.0185 11.08C28.2318 10.6867 28.5218 10.38 28.8885 10.16C29.2618 9.93334 29.6885 9.82 30.1685 9.82C30.6352 9.82 31.0385 9.93667 31.3785 10.17C31.7185 10.3967 31.9818 10.7167 32.1685 11.13C32.3552 11.5433 32.4485 12.03 32.4485 12.59H28.3185L28.5185 12.42C28.5185 12.82 28.5885 13.1633 28.7285 13.45C28.8752 13.7367 29.0785 13.9567 29.3385 14.11C29.5985 14.2567 29.8952 14.33 30.2285 14.33C30.5818 14.33 30.8785 14.2467 31.1185 14.08C31.3652 13.9133 31.5552 13.6933 31.6885 13.42L32.3785 13.77C32.2519 14.03 32.0818 14.2567 31.8685 14.45C31.6618 14.6433 31.4152 14.7933 31.1285 14.9C30.8485 15.0067 30.5352 15.06 30.1885 15.06ZM28.5685 12.08L28.3585 11.92H31.8085L31.5985 12.09C31.5985 11.77 31.5352 11.4933 31.4085 11.26C31.2818 11.0267 31.1118 10.8467 30.8985 10.72C30.6852 10.5933 30.4385 10.53 30.1585 10.53C29.8852 10.53 29.6252 10.5933 29.3785 10.72C29.1385 10.8467 28.9418 11.0267 28.7885 11.26C28.6418 11.4867 28.5685 11.76 28.5685 12.08Z"
                fill="#586068"
              />
              <Path
                d="M37.1575 14.94L37.1175 14.01V7.94H37.9175V14.94H37.1575ZM35.4275 15.06C34.9808 15.06 34.5875 14.95 34.2475 14.73C33.9075 14.51 33.6408 14.2033 33.4475 13.81C33.2608 13.41 33.1675 12.9533 33.1675 12.44C33.1675 11.92 33.2608 11.4633 33.4475 11.07C33.6408 10.6767 33.9075 10.37 34.2475 10.15C34.5875 9.93 34.9808 9.82 35.4275 9.82C35.8475 9.82 36.2142 9.93 36.5275 10.15C36.8475 10.37 37.0942 10.6767 37.2675 11.07C37.4408 11.4633 37.5275 11.92 37.5275 12.44C37.5275 12.9533 37.4408 13.41 37.2675 13.81C37.0942 14.2033 36.8475 14.51 36.5275 14.73C36.2142 14.95 35.8475 15.06 35.4275 15.06ZM35.6075 14.33C35.9008 14.33 36.1608 14.25 36.3875 14.09C36.6142 13.93 36.7908 13.7067 36.9175 13.42C37.0508 13.1333 37.1175 12.8067 37.1175 12.44C37.1175 12.0733 37.0508 11.7467 36.9175 11.46C36.7908 11.1733 36.6142 10.95 36.3875 10.79C36.1608 10.63 35.8975 10.55 35.5975 10.55C35.2842 10.55 35.0042 10.63 34.7575 10.79C34.5175 10.95 34.3275 11.1733 34.1875 11.46C34.0475 11.7467 33.9775 12.0733 33.9775 12.44C33.9775 12.8067 34.0475 13.1333 34.1875 13.42C34.3275 13.7067 34.5208 13.93 34.7675 14.09C35.0142 14.25 35.2942 14.33 35.6075 14.33Z"
                fill="#586068"
              />
              <Path
                d="M41.7876 14.94V7.94H42.5876V14.01L42.5476 14.94H41.7876ZM44.2776 15.06C43.8576 15.06 43.4876 14.95 43.1676 14.73C42.8542 14.51 42.6109 14.2033 42.4376 13.81C42.2642 13.41 42.1776 12.9533 42.1776 12.44C42.1776 11.92 42.2642 11.4633 42.4376 11.07C42.6109 10.6767 42.8542 10.37 43.1676 10.15C43.4876 9.93 43.8576 9.82 44.2776 9.82C44.7242 9.82 45.1176 9.93 45.4576 10.15C45.7976 10.37 46.0609 10.6767 46.2476 11.07C46.4409 11.4633 46.5376 11.92 46.5376 12.44C46.5376 12.9533 46.4409 13.41 46.2476 13.81C46.0609 14.2033 45.7976 14.51 45.4576 14.73C45.1176 14.95 44.7242 15.06 44.2776 15.06ZM44.0976 14.33C44.4109 14.33 44.6876 14.25 44.9276 14.09C45.1742 13.93 45.3676 13.7067 45.5076 13.42C45.6542 13.1333 45.7276 12.8067 45.7276 12.44C45.7276 12.0733 45.6576 11.7467 45.5176 11.46C45.3842 11.1733 45.1942 10.95 44.9476 10.79C44.7009 10.63 44.4209 10.55 44.1076 10.55C43.8142 10.55 43.5509 10.63 43.3176 10.79C43.0909 10.95 42.9109 11.1733 42.7776 11.46C42.6509 11.7467 42.5876 12.0733 42.5876 12.44C42.5876 12.8067 42.6509 13.1333 42.7776 13.42C42.9109 13.7067 43.0909 13.93 43.3176 14.09C43.5442 14.25 43.8042 14.33 44.0976 14.33Z"
                fill="#586068"
              />
              <Path
                d="M47.97 17.06C47.8433 17.06 47.6967 17.0333 47.53 16.98C47.3633 16.9333 47.1867 16.87 47 16.79L47.3 16.12C47.4533 16.1867 47.5867 16.2367 47.7 16.27C47.82 16.3033 47.9133 16.32 47.98 16.32C48.16 16.32 48.3067 16.27 48.42 16.17C48.54 16.0767 48.64 15.94 48.72 15.76L49.29 14.4L51.14 9.94H52L49.6 15.64C49.4733 15.9333 49.34 16.1867 49.2 16.4C49.0667 16.6133 48.9033 16.7767 48.71 16.89C48.5167 17.0033 48.27 17.06 47.97 17.06ZM49.07 14.94L46.91 9.94H47.76L49.62 14.39L49.86 14.94H49.07Z"
                fill="#586068"
              />
              <Path
                d="M63.4543 13.1613C61.2503 13.1613 60 11.7792 60 9.27031C60 5.44312 62.7338 2.44515 66.4 2.44515C67.7562 2.44515 68.7523 2.84913 69.3033 3.3169L70.6807 2.46641H71.8251L70.1297 10.461C70.0662 10.7587 70.0238 10.9288 70.0238 11.0776C70.0238 11.3753 70.1721 11.5028 70.4264 11.5028C70.7443 11.5028 71.041 11.3115 71.3589 10.9925L71.592 10.7587L71.8251 11.4603L71.6132 11.7367C70.7655 12.7786 69.9602 13.1613 68.8794 13.1613C67.6503 13.1613 66.951 12.651 66.8238 11.6729C66.7814 11.4816 66.7814 11.2477 66.8238 10.9713C66.0185 12.2683 64.9801 13.1613 63.4543 13.1613ZM64.9589 11.2477C65.7006 11.2477 66.4 10.7587 66.9721 9.88691L68.1377 4.35875C67.7139 3.89098 67.2264 3.6571 66.5907 3.6571C64.9589 3.6571 63.4755 6.14477 63.4755 9.12148C63.4755 10.6311 63.9417 11.2477 64.9589 11.2477Z"
                fill="#586068"
              />
              <Path
                d="M76.0936 13.1613C74.5042 13.1613 73.1055 12.9274 72.1943 12.5872L72.046 9.90818H72.9996L73.4658 10.4823C74.4406 11.6517 75.0976 11.9281 76.1148 11.9281C77.4075 11.9281 77.9797 11.4816 77.9797 10.6949C77.9797 9.90818 77.4075 9.54672 75.8181 9.14274C73.1903 8.48361 72.1731 7.67565 72.1731 5.82584C72.1731 3.72088 74.0804 2.44515 76.7506 2.44515C78.1492 2.44515 79.4207 2.67903 80.3108 2.99797L80.4591 5.54943H79.5055L79.0393 4.91156C78.34 3.95477 77.8737 3.67836 76.8353 3.67836C75.7757 3.67836 75.1188 4.08234 75.1188 4.84778C75.1188 5.61322 75.691 5.99593 77.5347 6.39992C80.0565 6.974 80.9466 7.90953 80.9466 9.73808C80.9466 11.8856 79.0605 13.1613 76.0936 13.1613Z"
                fill="#586068"
              />
              <Path
                d="M85.5137 13.1613C83.3098 13.1613 82.0595 12.3108 82.0595 9.80187V4.1036H80.6396V3.10428C82.1654 2.23253 82.9071 1.72224 84.0939 0.637865L84.7508 0H85.4502V2.65777H88.6078V4.1036H85.4502V9.73808C85.4502 10.865 85.8952 11.3965 86.8065 11.3965C87.3574 11.3965 87.8449 11.2264 88.4382 10.7374L88.9256 11.354C88.1204 12.6723 87.0184 13.1613 85.5137 13.1613Z"
                fill="#586068"
              />
              <Path
                d="M97.9477 10.057L98.5411 10.5886C97.6934 12.1407 96.104 13.1613 93.8153 13.1613C90.4669 13.1613 88.157 11.3115 88.157 7.80322C88.157 4.42253 90.5941 2.44515 93.6669 2.44515C96.8457 2.44515 98.5199 4.23117 98.5199 7.31419V7.69691H91.7384C91.8232 10.4397 93.01 11.2477 94.8749 11.2477C96.0828 11.2477 97.0576 10.9075 97.9477 10.057ZM93.6669 3.67836C92.5649 3.67836 91.908 4.76273 91.7596 6.6338L95.2351 6.44244C95.3199 4.7202 94.8113 3.67836 93.6669 3.67836Z"
                fill="#586068"
              />
              <Path
                d="M104.44 12.9487H98.1246V12.1194L98.5908 11.9919C99.3325 11.7792 99.3749 11.5028 99.3749 10.6524V5.40059C99.3749 4.65642 99.2477 4.42253 98.7179 4.29496L98.1669 4.14613V3.33816L102.299 2.44515H102.787L102.532 4.95409C103.465 3.33816 104.736 2.44515 106.008 2.44515C107.258 2.44515 108 3.18933 108 4.40127C108 5.52817 107.258 6.29361 106.156 6.29361C105.139 6.29361 104.461 5.63448 104.461 4.7202C104.461 4.61389 104.461 4.55011 104.482 4.46506C103.931 4.65642 103.317 5.08166 102.787 5.78331V10.6524C102.787 11.5028 102.893 11.7792 103.698 11.9493L104.44 12.1194V12.9487Z"
                fill="#586068"
              />
              <Path
                d="M63.2667 20.7343L64.3692 17.1613H64.803L63.6954 20.7343H63.2667ZM61.0923 20.7343L60 17.1613H60.4339L61.5159 20.7343H61.0923ZM61.1382 20.7343L62.1897 17.1613H62.6031L61.5517 20.7343H61.1382ZM63.231 20.7343L62.205 17.1613H62.6185L63.6597 20.7343H63.231Z"
                fill="#586068"
              />
              <Path
                d="M66.1299 20.7955C65.8815 20.7955 65.6603 20.7394 65.4663 20.6271C65.2758 20.5114 65.126 20.3531 65.0171 20.1524C64.9083 19.9516 64.8538 19.7202 64.8538 19.4582C64.8538 19.1962 64.9066 18.9648 65.012 18.764C65.1209 18.5633 65.2707 18.4067 65.4612 18.2944C65.6552 18.1788 65.8746 18.1209 66.1196 18.1209C66.3715 18.1209 66.5926 18.1788 66.7832 18.2944C66.9772 18.4067 67.1269 18.5633 67.2324 18.764C67.3413 18.9648 67.3957 19.1962 67.3957 19.4582C67.3957 19.7202 67.3413 19.9516 67.2324 20.1524C67.1269 20.3531 66.9789 20.5114 66.7883 20.6271C66.5977 20.7394 66.3783 20.7955 66.1299 20.7955ZM66.1299 20.4178C66.3 20.4178 66.448 20.377 66.5739 20.2953C66.6998 20.2136 66.7985 20.1013 66.87 19.9584C66.9448 19.8155 66.9823 19.6488 66.9823 19.4582C66.9823 19.2676 66.9448 19.1009 66.87 18.958C66.7985 18.8151 66.6981 18.7028 66.5688 18.6211C66.4395 18.5394 66.2898 18.4986 66.1196 18.4986C65.9529 18.4986 65.8049 18.5394 65.6756 18.6211C65.5497 18.7028 65.4493 18.8151 65.3744 18.958C65.303 19.1009 65.2672 19.2676 65.2672 19.4582C65.2672 19.6454 65.303 19.8121 65.3744 19.9584C65.4493 20.1013 65.5514 20.2136 65.6807 20.2953C65.8134 20.377 65.9631 20.4178 66.1299 20.4178Z"
                fill="#586068"
              />
              <Path
                d="M67.9627 20.7343V18.1822H68.3455L68.3659 18.5394C68.4442 18.4033 68.5445 18.2996 68.667 18.2281C68.7895 18.1566 68.9325 18.1209 69.0958 18.1209C69.3 18.1209 69.4735 18.1685 69.6164 18.2638C69.7627 18.3591 69.8682 18.5037 69.9329 18.6977C70.0043 18.5105 70.1081 18.3676 70.2442 18.2689C70.3838 18.1702 70.5488 18.1209 70.7393 18.1209C71.0252 18.1209 71.2498 18.2162 71.4131 18.4067C71.5798 18.5973 71.6615 18.8865 71.6581 19.2745V20.7343H71.2498V19.4276C71.2498 19.1758 71.2225 18.9835 71.1681 18.8508C71.1171 18.7181 71.0473 18.6279 70.9588 18.5803C70.8704 18.5292 70.7683 18.5037 70.6526 18.5037C70.4518 18.5037 70.2953 18.5837 70.183 18.7436C70.0707 18.9001 70.0145 19.123 70.0145 19.4123V20.7343H69.6062V19.4276C69.6062 19.1758 69.579 18.9835 69.5245 18.8508C69.4735 18.7181 69.402 18.6279 69.3102 18.5803C69.2217 18.5292 69.1196 18.5037 69.0039 18.5037C68.8066 18.5037 68.6517 18.5837 68.5394 18.7436C68.4271 18.9001 68.371 19.123 68.371 19.4123V20.7343H67.9627Z"
                fill="#586068"
              />
              <Path
                d="M73.422 20.7955C73.1736 20.7955 72.9542 20.7394 72.7636 20.6271C72.573 20.5114 72.4233 20.3531 72.3144 20.1524C72.2089 19.9516 72.1562 19.7202 72.1562 19.4582C72.1562 19.1962 72.2089 18.9648 72.3144 18.764C72.4233 18.5633 72.5713 18.4067 72.7585 18.2944C72.949 18.1788 73.1668 18.1209 73.4118 18.1209C73.65 18.1209 73.8559 18.1805 74.0294 18.2996C74.203 18.4152 74.3374 18.5786 74.4327 18.7896C74.5279 19.0005 74.5756 19.2489 74.5756 19.5348H72.4676L72.5696 19.448C72.5696 19.6522 72.6054 19.8274 72.6768 19.9737C72.7517 20.12 72.8555 20.2323 72.9882 20.3106C73.1209 20.3855 73.2723 20.4229 73.4425 20.4229C73.6228 20.4229 73.7742 20.3804 73.8967 20.2953C74.0226 20.2102 74.1196 20.0979 74.1877 19.9584L74.5399 20.1371C74.4752 20.2698 74.3884 20.3855 74.2795 20.4841C74.1741 20.5828 74.0482 20.6594 73.9018 20.7138C73.7589 20.7683 73.599 20.7955 73.422 20.7955ZM72.5952 19.2745L72.488 19.1928H74.2489L74.1417 19.2796C74.1417 19.1162 74.1094 18.975 74.0447 18.8559C73.9801 18.7368 73.8933 18.6449 73.7844 18.5803C73.6755 18.5156 73.5496 18.4833 73.4067 18.4833C73.2672 18.4833 73.1345 18.5156 73.0086 18.5803C72.8861 18.6449 72.7857 18.7368 72.7074 18.8559C72.6326 18.9716 72.5952 19.1111 72.5952 19.2745Z"
                fill="#586068"
              />
              <Path
                d="M75.0906 20.7343V18.1822H75.4734L75.4989 18.6517V20.7343H75.0906ZM76.8822 20.7343V19.4276H77.2905V20.7343H76.8822ZM76.8822 19.4276C76.8822 19.1758 76.8532 18.9835 76.7954 18.8508C76.7375 18.7181 76.6576 18.6279 76.5555 18.5803C76.4534 18.5292 76.3377 18.5037 76.2084 18.5037C75.9838 18.5037 75.8086 18.5837 75.6827 18.7436C75.5602 18.9001 75.4989 19.123 75.4989 19.4123H75.2999C75.2999 19.1434 75.339 18.9121 75.4173 18.7181C75.4955 18.5241 75.6095 18.3761 75.7592 18.274C75.909 18.1719 76.0893 18.1209 76.3003 18.1209C76.501 18.1209 76.6746 18.1617 76.8209 18.2434C76.9706 18.3217 77.0863 18.4476 77.168 18.6211C77.2531 18.7947 77.2939 19.0226 77.2905 19.3051V19.4276H76.8822Z"
                fill="#586068"
              />
              <Path
                d="M77.6714 18.2383L77.9623 17.1613H78.4217L78.0235 18.2383H77.6714Z"
                fill="#586068"
              />
              <Path
                d="M79.4132 20.7955C79.2261 20.7955 79.0627 20.7683 78.9232 20.7138C78.7837 20.656 78.6663 20.5811 78.571 20.4893C78.4791 20.3974 78.4128 20.297 78.3719 20.1881L78.7292 20.0401C78.7871 20.1558 78.8739 20.2494 78.9896 20.3208C79.1053 20.3923 79.2329 20.428 79.3724 20.428C79.5323 20.428 79.6667 20.3974 79.7756 20.3361C79.8845 20.2715 79.9389 20.1813 79.9389 20.0656C79.9389 19.9601 79.8981 19.875 79.8164 19.8104C79.7382 19.7457 79.6208 19.693 79.4642 19.6522L79.2192 19.5858C78.9879 19.5246 78.8075 19.4327 78.6782 19.3102C78.5489 19.1843 78.4842 19.0431 78.4842 18.8865C78.4842 18.6449 78.5625 18.4578 78.719 18.3251C78.879 18.189 79.1155 18.1209 79.4285 18.1209C79.5782 18.1209 79.7144 18.143 79.8369 18.1873C79.9594 18.2315 80.0648 18.2944 80.1533 18.3761C80.2418 18.4544 80.3064 18.548 80.3473 18.6568L79.99 18.8151C79.9457 18.6994 79.8726 18.616 79.7705 18.565C79.6684 18.5139 79.5442 18.4884 79.3979 18.4884C79.2414 18.4884 79.1189 18.5224 79.0304 18.5905C78.9419 18.6551 78.8977 18.747 78.8977 18.8661C78.8977 18.9342 78.9334 18.9988 79.0049 19.0601C79.0797 19.1179 79.1886 19.1656 79.3315 19.203L79.5867 19.2693C79.7535 19.3102 79.893 19.3714 80.0053 19.4531C80.1176 19.5314 80.2027 19.6215 80.2605 19.7236C80.3183 19.8257 80.3473 19.9346 80.3473 20.0503C80.3473 20.2034 80.3064 20.3361 80.2248 20.4484C80.1431 20.5607 80.0325 20.6475 79.893 20.7087C79.7535 20.7666 79.5936 20.7955 79.4132 20.7955Z"
                fill="#586068"
              />
              <Path
                d="M84.7312 20.7343V17.1613H85.16V20.7343H84.7312ZM82.2761 20.7343V17.1613H82.71V20.7343H82.2761ZM82.6589 19.0907V18.6977H84.8231V19.0907H82.6589Z"
                fill="#586068"
              />
              <Path
                d="M87.1097 20.7955C86.8612 20.7955 86.6418 20.7394 86.4512 20.6271C86.2607 20.5114 86.1109 20.3531 86.002 20.1524C85.8966 19.9516 85.8438 19.7202 85.8438 19.4582C85.8438 19.1962 85.8966 18.9648 86.002 18.764C86.1109 18.5633 86.259 18.4067 86.4461 18.2944C86.6367 18.1788 86.8544 18.1209 87.0994 18.1209C87.3376 18.1209 87.5435 18.1805 87.7171 18.2996C87.8906 18.4152 88.025 18.5786 88.1203 18.7896C88.2156 19.0005 88.2632 19.2489 88.2632 19.5348H86.1552L86.2572 19.448C86.2572 19.6522 86.293 19.8274 86.3644 19.9737C86.4393 20.12 86.5431 20.2323 86.6758 20.3106C86.8085 20.3855 86.9599 20.4229 87.1301 20.4229C87.3104 20.4229 87.4618 20.3804 87.5843 20.2953C87.7102 20.2102 87.8072 20.0979 87.8753 19.9584L88.2275 20.1371C88.1628 20.2698 88.076 20.3855 87.9672 20.4841C87.8617 20.5828 87.7358 20.6594 87.5894 20.7138C87.4465 20.7683 87.2866 20.7955 87.1097 20.7955ZM86.2828 19.2745L86.1756 19.1928H87.9365L87.8293 19.2796C87.8293 19.1162 87.797 18.975 87.7324 18.8559C87.6677 18.7368 87.5809 18.6449 87.472 18.5803C87.3632 18.5156 87.2373 18.4833 87.0943 18.4833C86.9548 18.4833 86.8221 18.5156 86.6962 18.5803C86.5737 18.6449 86.4733 18.7368 86.3951 18.8559C86.3202 18.9716 86.2828 19.1111 86.2828 19.2745Z"
                fill="#586068"
              />
              <Path
                d="M90.3503 20.7343L90.3299 20.3157V19.3867C90.3299 19.186 90.3078 19.0209 90.2635 18.8916C90.2193 18.7589 90.1495 18.6585 90.0543 18.5905C89.959 18.5224 89.8348 18.4884 89.6816 18.4884C89.5421 18.4884 89.4213 18.5173 89.3192 18.5752C89.2206 18.6296 89.1389 18.7181 89.0742 18.8406L88.7067 18.6977C88.7714 18.5786 88.8497 18.4765 88.9415 18.3914C89.0334 18.303 89.1406 18.2366 89.2631 18.1924C89.3856 18.1447 89.5251 18.1209 89.6816 18.1209C89.9198 18.1209 90.1155 18.1685 90.2686 18.2638C90.4252 18.3557 90.5426 18.4935 90.6208 18.6773C90.6991 18.8576 90.7365 19.0822 90.7331 19.351L90.728 20.7343H90.3503ZM89.5847 20.7955C89.2852 20.7955 89.0504 20.7274 88.8803 20.5913C88.7136 20.4518 88.6302 20.2596 88.6302 20.0146C88.6302 19.7559 88.7153 19.5586 88.8854 19.4225C89.0589 19.283 89.3005 19.2132 89.6102 19.2132H90.3401V19.5552H89.7021C89.4639 19.5552 89.2937 19.5943 89.1916 19.6726C89.093 19.7508 89.0436 19.8631 89.0436 20.0095C89.0436 20.1422 89.093 20.2477 89.1916 20.3259C89.2903 20.4008 89.4281 20.4382 89.6051 20.4382C89.7514 20.4382 89.879 20.4076 89.9879 20.3463C90.0968 20.2817 90.1802 20.1915 90.238 20.0758C90.2993 19.9567 90.3299 19.8172 90.3299 19.6573H90.5034C90.5034 20.0044 90.4252 20.2817 90.2686 20.4893C90.1121 20.6934 89.8841 20.7955 89.5847 20.7955Z"
                fill="#586068"
              />
              <Path
                d="M91.3801 20.7343V17.1613H91.7885V20.7343H91.3801Z"
                fill="#586068"
              />
              <Path
                d="M93.4613 20.7955C93.2129 20.7955 93.0206 20.7308 92.8845 20.6015C92.7484 20.4722 92.6803 20.2902 92.6803 20.0554V17.3859H93.0887V20.0146C93.0887 20.1439 93.1227 20.2442 93.1907 20.3157C93.2622 20.3838 93.3609 20.4178 93.4868 20.4178C93.5276 20.4178 93.5667 20.4127 93.6042 20.4025C93.645 20.3889 93.6978 20.3582 93.7624 20.3106L93.9206 20.6424C93.8322 20.7002 93.7522 20.7394 93.6807 20.7598C93.6093 20.7836 93.5361 20.7955 93.4613 20.7955ZM92.2363 18.5446V18.1822H93.8543V18.5446H92.2363Z"
                fill="#586068"
              />
              <Path
                d="M94.4307 20.7343V17.1613H94.839V20.7343H94.4307ZM96.2274 20.7343V19.4276H96.6357V20.7343H96.2274ZM96.2274 19.4276C96.2274 19.1758 96.1968 18.9835 96.1355 18.8508C96.0777 18.7181 95.9977 18.6279 95.8956 18.5803C95.7935 18.5292 95.6778 18.5037 95.5485 18.5037C95.3239 18.5037 95.1487 18.5837 95.0228 18.7436C94.9003 18.9001 94.839 19.123 94.839 19.4123H94.64C94.64 19.1434 94.6791 18.9121 94.7574 18.7181C94.839 18.5241 94.9547 18.3761 95.1045 18.274C95.2542 18.1719 95.4345 18.1209 95.6455 18.1209C95.8429 18.1209 96.0164 18.1617 96.1661 18.2434C96.3159 18.3217 96.4315 18.4476 96.5132 18.6211C96.5983 18.7947 96.6391 19.0226 96.6357 19.3051V19.4276H96.2274Z"
                fill="#586068"
              />
              <Path
                d="M98.6554 20.7343V17.1613H100.769V17.5543H99.0892V18.7079H100.62V19.0958H99.0892V20.3412H100.779V20.7343H98.6554Z"
                fill="#586068"
              />
              <Path
                d="M104.131 20.7343V17.1613H104.56V20.7343H104.131ZM101.676 20.7343V17.1613H102.11V20.7343H101.676ZM102.059 19.0907V18.6977H104.223V19.0907H102.059Z"
                fill="#586068"
              />
              <Path
                d="M105.509 20.7343V17.1613H106.673C106.904 17.1613 107.108 17.2073 107.285 17.2991C107.466 17.391 107.605 17.5186 107.704 17.6819C107.806 17.8453 107.857 18.0324 107.857 18.2434C107.857 18.4544 107.803 18.6415 107.694 18.8049C107.585 18.9682 107.437 19.0975 107.25 19.1928C107.063 19.2847 106.852 19.3306 106.617 19.3306H105.943V20.7343H105.509ZM107.525 20.7343L106.668 19.2693L107.01 19.0448L108 20.7343H107.525ZM105.943 18.9325H106.734C106.863 18.9325 106.979 18.9035 107.081 18.8457C107.183 18.7844 107.265 18.7028 107.326 18.6007C107.391 18.4952 107.423 18.3761 107.423 18.2434C107.423 18.0392 107.352 17.8742 107.209 17.7483C107.069 17.619 106.887 17.5543 106.663 17.5543H105.943V18.9325Z"
                fill="#586068"
              />
            </Svg>
          </Page>
        );
      })}
    </Document>
  );
};

export default GenerateEncountersPdf;
