import { createStorageKey } from '@aster/shared/utils';
import { useAuth } from '../../../authentication/AuthProvider';
import { useSnackbar } from '../../../components/Snack';
import { getS3SignedUrl } from '@aster/shared/utils';
import { useQuery } from '@tanstack/react-query';

async function generateLabsResultsURL(
  practiceID: string,
  labsOrderID: string,
  onError: () => void
) {
  const url = await getS3SignedUrl({
    Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
    s3key: createStorageKey({
      practiceId: practiceID,
      scope: 'labs',
      name: [labsOrderID, `results.pdf`].join('/'),
    }),
    fileName: `results.pdf`,
    onError,
  });
  return url;
}
export function useLabsPDFUrl(labsOrderID: string | null) {
  const { showMessage } = useSnackbar();
  const { profile } = useAuth();
  const { data: pdfFileUrl, isLoading: isPDFFileURLBeingGenerated } = useQuery({
    queryKey: ['labs-results-pdf-url', labsOrderID],
    queryFn: () =>
      generateLabsResultsURL(
        profile?.practiceId as string,
        labsOrderID as string,
        () =>
          showMessage({
            message: 'Failed to load lab results PDF',
            type: 'error',
          })
      ),
    enabled: !!labsOrderID && !!profile?.practiceId,
  });

  return { pdfFileUrl, isPDFFileURLBeingGenerated };
}
