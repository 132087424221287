import { useSnackbar } from '../../../components/Snack';
import { Profile, useAuth } from '../../../authentication/AuthProvider';
import { useMutation } from '@tanstack/react-query';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { ExtendedError } from '../../../components/ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';
import { createStorageKey } from '@aster/shared/utils';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { LabsOrderDTO, TryVitalResult } from '@aster/app/core/shared/dtos/labs';
import axios from '../../../app/axiosConfig';
import { getS3ClientWithCredentials } from '@aster/shared/utils';

async function uploadLabResultsPDF({
  resultsPDF,
  profile,
  labsOrderID,
}: {
  labsOrderID: string;
  resultsPDF: File;
  profile: Profile;
}) {
  const s3 = await getS3ClientWithCredentials();

  if (!s3) throw new Error('Permission required to upload files');

  const Bucket = import.meta.env.VITE_DOCUMENTS_BUCKET;
  const Key = createStorageKey({
    practiceId: profile.practiceId,
    scope: 'labs',
    name: [labsOrderID, `results.pdf`].join('/'),
  });
  const Body = resultsPDF;
  const ContentType = resultsPDF.type;

  const object = await s3.send(
    new PutObjectCommand({
      Bucket,
      Key,
      Body,
      ContentType,
    })
  );

  return object;
}

async function requestLabResultsPDFProcessing(labsOrderID: string) {
  const response = await axios.post<LabsOrderDTO['results']>(
    `/v2/labs/orders/${labsOrderID}/process-results-pdf`
  );
  return response.data;
}

async function uploadAndProcessResultsPDF({
  resultsPDF,
  profile,
  labsOrderID,
}: {
  resultsPDF: File;
  profile: Profile;
  labsOrderID: string | null;
}) {
  if (!labsOrderID) {
    return null;
  }

  await uploadLabResultsPDF({ resultsPDF, profile, labsOrderID });

  // Not being able  to upload the file is consider an process error
  // but we continue the process if the data extraction fails
  try {
    return requestLabResultsPDFProcessing(labsOrderID);
  } catch (error) {
    return null;
  }
}

export const useProcessResultsPDFMutation = ({
  onSuccess,
  onError,
  labsOrderID,
}: {
  labsOrderID: string | null;
  onSuccess?: (data: TryVitalResult | null) => void;
  onError?: () => void;
}) => {
  const { profile } = useAuth();
  const { showMessage } = useSnackbar();

  const { mutateAsync: processResultsPDF, ...rest } = useMutation({
    mutationKey: ['processResultsPDF'],
    mutationFn: (resultsPDF: File) =>
      uploadAndProcessResultsPDF({
        resultsPDF,
        profile: profile as Profile,
        labsOrderID,
      }),
    scope: {
      id: createMutationScopeID(MutationScope.LABS_ORDER),
    },
    onSuccess: (data) => {
      showMessage({
        type: 'success',
        message: 'The lab results have been successfully processed!',
      });

      onSuccess?.(data);
    },
    onError: (error) => {
      const processingLabResultPDFError: ExtendedError = new Error(
        error.message
      );

      processingLabResultPDFError.name = `PracticeLogoUploadError`;
      processingLabResultPDFError.stack = error.stack as string | undefined;
      processingLabResultPDFError.cause = error;

      datadogRum.addError(processingLabResultPDFError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while processing the lab order results PDF. Please try again!',
      });

      onError?.();
    },
  });

  return { processResultsPDF, ...rest };
};
