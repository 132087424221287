import { useLabsStore } from '../../store/labs.store';
import Divider from '@mui/material/Divider';
import ButtonType from '../../../../components/Button';
import { useLabsPDFUrl } from '../../hooks/useLabsPDFUrl';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import FillResultsHeader from './FillResultsHeader';
import FillResultsPDF from './FillResultsPDF';
import { CreateOrderDTO } from '@aster/app/core/shared/dtos/labs';
import { useParams } from 'react-router-dom';
import { useCreateLabsOrderMutation } from '../../mutations/create-labs-order.mutation';
import FillResultsForm from './FillResultsForm';
import Typography from '../../../../components/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { parseDate } from '@aster/shared/utils';
import { scrollToFirstInvalidField } from '../../../../utils';

export default function FillResults() {
  const { patient } = useParams();
  const labsOrderID = useLabsStore((state) => state.generatedLabOrderID);
  const processedResults = useLabsStore((state) => state.processedResults);
  const closeManualUploadResultsDialog = useLabsStore(
    (state) => state.closeManualUploadResultsDialog
  );
  const generatedLabOrderID = useLabsStore(
    (state) => state.generatedLabOrderID
  );
  const { pdfFileUrl } = useLabsPDFUrl(labsOrderID);
  const addNewOrderID = useLabsStore((state) => state.addNewOrderID);
  const { createLabsOrderMutation } = useCreateLabsOrderMutation(
    (newOrderId) => {
      addNewOrderID(newOrderId);
      closeManualUploadResultsDialog();
      form.reset();
    }
  );
  const form = useForm({
    defaultValues: {
      patientID: patient,
      panelName: '',
      interpretation: processedResults?.metadata.interpretation ?? 'normal',
      orderedBy: '',
      date_collected: processedResults?.metadata.date_collected ?? '',
      date_received: processedResults?.metadata.date_received ?? '',
      date_reported: processedResults?.metadata.date_reported ?? '',
      laboratory: processedResults?.metadata.laboratory ?? '',
      results: processedResults?.results ?? [],
      notes: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      const order: CreateOrderDTO = {
        id: generatedLabOrderID as string,
        patientID: value.patientID as string,
        isManualOrder: true,
        orderedBy: value.orderedBy,
        labTestName: value.panelName,
        results: {
          metadata: {
            date_collected: parseDate(value.date_collected),
            date_received: parseDate(value.date_received),
            date_reported: parseDate(value.date_reported),
            interpretation: value.interpretation,
            laboratory: value.laboratory,
            notes: value.notes,
          },
          results: value.results.map((result) => {
            return {
              name: result.name,
              result: result.result,
              unit: result.unit ?? undefined,
              interpretation: result.interpretation ?? 'normal',
              min_range_value: result.min_range_value
                ? Number.parseFloat(result.min_range_value.toString())
                : undefined,
              max_range_value: result.max_range_value
                ? Number.parseFloat(result.max_range_value.toString())
                : undefined,
            };
          }),
        },
      };

      return createLabsOrderMutation.mutateAsync(order);
    },
  });

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit().then(scrollToFirstInvalidField);
      }}
      className="grid auto-rows-fill-first h-full"
    >
      <section className="grid grid-cols-3 3xl:grid-cols-2 grid-rows-fill-last gap-y-5 flex-1 h-full overflow-y-hidden">
        <FillResultsHeader pdfFileUrl={pdfFileUrl} />
        <FillResultsPDF pdfFileUrl={pdfFileUrl} />
        <FillResultsForm form={form} />
      </section>
      <Divider className="border-gray-200 my-4" />
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <FontAwesomeIcon
            icon={faSparkles}
            size="sm"
            className="text-green-600"
          />
          <Typography variant="bodySmall">
            Aster uses Vertex AI from Google. It can make mistakes, so please
            check your data carefully.
          </Typography>
        </div>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <ButtonType
              disabled={!canSubmit}
              loading={isSubmitting}
              type="submit"
              text="Save"
              variant="contained"
            />
          )}
        />
      </div>
    </form>
  );
}
