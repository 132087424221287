import { useState, useMemo, useRef, MutableRefObject } from 'react';
import {
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import midwife from '../../assets/midwife.webp';
import ButtonType from '../../components/Button';
import Users from '../../assets/icons/Users';
import RegisterPatient from './RegisterPatient';
import dayjs from 'dayjs';
import { formatMRN } from '../../utils';
import StyledDataGrid from '../../components/StyledDataGrid';
import SearchInput from '../../components/SearchInput';
import { names } from '@aster/shared/utils';
import Chip from '../../components/Chip';
import Typography from '../../components/Typography';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import PatientFilters from './PatientFilters';
import { usePatientSearch } from './hooks/usePatientSearch';

const Patients = () => {
  const tableRef = useRef<GridApiCommunity>(null);
  const navigate = useNavigate();
  const [showRegisterPatientDialog, setShowRegisterPatientDialog] =
    useState(false);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    paginatedPatients,
    arePatientsLoading,
    arePatientsFetching,
    paginationModel,
    setPaginationModel,
    setDataGridSortModel,
    dataGridSortModel,
    updateFilter,
    clearFilters,
    filterState,
    search,
  } = usePatientSearch(true);

  const rows = useMemo(() => {
    if (!paginatedPatients) return [];

    return paginatedPatients.items.map((r) => {
      return {
        id: r.id,
        name: `${r.firstName} ${r.lastName}`,
        mrn: formatMRN(r.mrn),
        dateOfBirth: r.dateOfBirth
          ? dayjs(r.dateOfBirth).format('MM/DD/YYYY')
          : '-',
        dueDate: r.calculatedDueDate
          ? dayjs(r.calculatedDueDate).format('MM/DD/YYYY')
          : '-',
        gestationalAge: r.gestationalAge,
        lastVisit: r.lastVisit ? dayjs(r.lastVisit).format('MM/DD/YYYY') : '-',
        email: r.email,
        phoneNumber: r.phoneNumber,
        status: names(r.status).sentenceCase,
      };
    });
  }, [paginatedPatients]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      headerAlign: 'left',
      width: 110,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'gestationalAge',
      headerName: 'Gest. Age',
      description: 'Gestation Age',
      headerAlign: 'left',
      width: 120,
      headerClassName: 'bg-grayBackground',
      renderCell: (params) => {
        const gaValues = params.row.gestationalAge?.match(/\d+/g);
        const ga = gaValues && {
          gestationalWeeks: Number.parseInt(gaValues[0]),
          gestationalDays: Number.parseInt(gaValues[1]),
        };

        let color: string | null = 'bg-primary';

        if (!ga) {
          return '-';
        }
        //  color based on gestational age
        if (ga.gestationalWeeks >= 28) {
          color = 'bg-pink-600';
        } else if (ga.gestationalWeeks >= 14) {
          color = 'bg-indigo-500';
        } else if (ga.gestationalWeeks > 0) {
          color = 'bg-sky-400';
        }

        return (
          <Chip
            label={params.row.gestationalAge}
            classes={{ root: `${color} text-white` }}
          />
        );
      },
    },
    {
      field: 'lastVisit',
      headerName: 'Last Encounter',
      headerAlign: 'left',
      flex: 0.6,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'mrn',
      headerName: 'Client ID',
      headerAlign: 'left',
      flex: 0.6,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
      description: 'Date of Birth',
      headerAlign: 'left',
      flex: 0.6,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'left',
      flex: 1.3,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      width: 110,
      headerClassName: 'bg-grayBackground',
    },
  ];

  if (arePatientsLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  if (rows.length < 1 && !search) {
    return (
      <div className="full-w-container flex-col items-center">
        <img src={midwife} alt="Midwife" width={'250px'} height={'162px'} />
        <div className="flex flex-col items-center mb-10">
          <p className="text-h3 text-primary">Your patient list is empty</p>
          <p className="text-body text-gray-500 ">
            Let's get started by adding your first patient.
          </p>
        </div>

        <ButtonType
          variant="contained"
          onClick={() => {
            setShowRegisterPatientDialog(true);
          }}
          text="Add new patient"
          classes={'w-[300px]'}
        />
        <br />

        <Tooltip title="Importing an existing patient list is coming soon.">
          <div>
            <ButtonType
              variant="outlined"
              text="Import existing patient"
              classes={'mt-5 w-[300px]'}
              disabled
            />
          </div>
        </Tooltip>
        <RegisterPatient
          open={showRegisterPatientDialog}
          setOpen={setShowRegisterPatientDialog}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col full-w-container overflow-auto">
      <div className="flex justify-between mb-4 flex-wrap gap-y-2">
        <div className="flex items-center gap-2">
          <Users className="h-5 w-auto" />
          <Typography variant="h4" text="Patient List" />
        </div>

        <div className="flex gap-4 flex-wrap self-center">
          <ButtonType
            variant="contained"
            onClick={() => setShowRegisterPatientDialog(true)}
            text="Add new patient"
          />
        </div>
      </div>
      <div className="w-full h-fit mb-2 grid">
        <div className="flex justify-between mb-4 items-center">
          <PatientFilters
            updateFilter={updateFilter}
            filterState={filterState}
            clearFilters={clearFilters}
          />
          <SearchInput
            onChange={(e) => search(e.currentTarget.value)}
            placeholder="Search by patient name"
            className="max-w-xs"
          />
        </div>
        <StyledDataGrid
          rows={rows}
          apiRef={tableRef as MutableRefObject<GridApiCommunity>}
          rowCount={paginatedPatients?.queryMetadata.rowCount ?? -1}
          loading={arePatientsLoading || arePatientsFetching}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={setDataGridSortModel}
          paginationModel={paginationModel}
          initialState={{
            sorting: { sortModel: dataGridSortModel as GridSortModel },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ) => {
            setRowSelectionModel(newRowSelectionModel);
            const found = rows.find(
              (ele: any) => ele.id === newRowSelectionModel[0]
            ) || { id: '' };

            if (found.id) {
              navigate(`/patientProfile/${found.id}`);
            }
          }}
          rowSelectionModel={rowSelectionModel}
          getRowClassName={() => 'cursor-pointer'}
        />
      </div>
      <RegisterPatient
        open={showRegisterPatientDialog}
        setOpen={setShowRegisterPatientDialog}
      />
    </div>
  );
};

export default Patients;
