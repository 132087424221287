import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchPatientProfile = async (patientID: string) => {
  const response = await axios.get<PatientProfileForEncounterDTO>(
    `/v2/patients/${patientID}/profile`
  );
  return response.data;
};

export function usePatientProfileQuery(
  patientID?: string,
  options?: { offline: boolean }
) {
  const { data: patientProfile, isLoading: isPatientProfileLoading } = useQuery(
    {
      queryKey: ['patientProfile', patientID],
      queryFn: () => fetchPatientProfile(patientID as string),
      enabled: !!patientID,
      networkMode: options?.offline ? 'offlineFirst' : 'online',
    }
  );
  return { patientProfile, isPatientProfileLoading };
}
