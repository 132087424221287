import { getS3SignedUrl } from '@aster/shared/utils';

export async function downloadFileFromS3(
  s3key: string,
  fileName: string,
  onError: (error?: Error) => void
): Promise<void> {
  const Bucket = import.meta.env.VITE_DOCUMENTS_BUCKET;
  const url = await getS3SignedUrl({ Bucket, s3key, fileName, onError });
  if (!url) return;

  return downLoadFileFromUrl(url, fileName, onError);
}

export async function downLoadFileFromUrl(
  url: string,
  fileName: string,
  onError: (error?: Error) => void
): Promise<void> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      onError();
      return;
    }

    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName || 'download';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the blob URL
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    return onError(error instanceof Error ? error : undefined);
  }
}

export async function processS3Url(
  s3key: string,
  onUrl: (url: string) => void,
  onError: (error?: Error) => void
) {
  try {
    const Bucket = import.meta.env.VITE_DOCUMENTS_BUCKET;
    const url = await getS3SignedUrl({ Bucket, s3key, fileName: '', onError });
    if (!url) return;
    onUrl(url);
  } catch (error) {
    onError(error instanceof Error ? error : undefined);
  }
}
