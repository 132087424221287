import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import {
  LabsOrderDTO,
  TryVitalResultEntry,
} from '@aster/app/core/shared/dtos/labs';
import dayjs from 'dayjs';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Typography from '../../../components/Typography';
import { ThemedCardContent, ThemedCardHeader } from '../../../components/Card';
import Tag from '../../../components/Tag';
import { resultSeverityClassColor } from '../../notes/sections/utils';
import { cn } from '@aster/client/utils/cn';

type LabTestCardProps = {
  labOrder: LabsOrderDTO;
  showDetails?: () => void;
  isNew?: boolean;
};

export default function LabTestCard({
  labOrder,
  showDetails,
  isNew,
}: LabTestCardProps) {
  const { results, createdAt, labTest, orderedBy, status } = labOrder;
  const outOfRangeResult = (
    results?.results as TryVitalResultEntry[] | null
  )?.find(
    (resultEntry) =>
      resultEntry.is_above_max_range ||
      resultEntry.is_above_max_range ||
      resultEntry.interpretation === 'abnormal' ||
      resultEntry.interpretation === 'critical'
  );

  return (
    <Card
      elevation={0}
      className="w-full rounded-[0.625rem] border border-grayBackground"
    >
      <ThemedCardHeader
        classes={{
          action: 'my-auto',
        }}
        action={
          <>
            {isNew && (
              <Tag
                tooltip="Recently created"
                tagText="New"
                color="main"
                textColor="white"
              />
            )}
            {(status === 'completed' || status === 'manually_uploaded') && (
              <IconButton onClick={showDetails} aria-label="see details">
                <ChevronRight />
              </IconButton>
            )}
          </>
        }
        title={labTest.name}
        subheader={
          results
            ? dayjs(results.metadata.date_reported).format('MM/DD/YYYY')
            : `${dayjs(createdAt).format('MM/DD/YYYY')} by ${orderedBy}`
        }
      />
      <ThemedCardContent
        className={outOfRangeResult ? 'justify-between' : 'justify-center'}
      >
        <Typography
          variant="bodySmall"
          text={
            outOfRangeResult
              ? outOfRangeResult.name
              : results?.metadata.interpretation ?? status
          }
        ></Typography>
        {outOfRangeResult && (
          <div className="flex gap-2 items-center">
            <Typography
              customClass={resultSeverityClassColor(
                outOfRangeResult.interpretation
              )}
              variant="bodySmall"
              text={`${outOfRangeResult.result} ${outOfRangeResult.unit}`}
            ></Typography>
            <div
              className={cn(
                'rounded-full h-4 w-4 text-white grid place-content-center',
                resultSeverityClassColor(
                  outOfRangeResult.interpretation ?? '',
                  'bg'
                )
              )}
            >
              <p className="text-bodySmall">
                {outOfRangeResult.is_above_max_range ? 'H' : 'L'}
              </p>
            </div>
          </div>
        )}
      </ThemedCardContent>
    </Card>
  );
}
