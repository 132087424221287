import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';

const enablePatientPortalAccess = async (patientID: string) =>
  axios.post(`/v2/patients/${patientID}/enable-portal-access`);

export function useEnablePatientPortalAccessMutation(patientID: string) {
  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();
  const enablePatientPortalAccessMutation = useMutation({
    mutationFn: () => enablePatientPortalAccess(patientID),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['patientProfile', patientID],
      });
      showMessage({
        type: 'success',
        message: `The patient has been invited to access the patient portal`,
      });
    },
    onError: () => {
      showMessage({
        type: 'error',
        message:
          'An error occurred while inviting the patient to access the patient portal',
      });
    },
  });

  return { enablePatientPortalAccessMutation };
}
