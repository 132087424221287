import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { useSnackbar } from '../../../components/Snack';

const sendIntakeForm = async (patientID: string) =>
  axios.post(`/v2/patients/${patientID}/send-intake-form`);

export function useSendIntakeFormMutation({
  patientID,
  patientEmail,
}: {
  patientID: string;
  patientEmail?: string;
}) {
  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();
  const sendIntakeFormMutation = useMutation({
    mutationFn: () => sendIntakeForm(patientID),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['patientProfile', patientID],
      });
      showMessage({
        type: 'success',
        message: `Intake form link sent to ${
          patientEmail ?? 'the patient'
        } successfully`,
      });
    },
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while sending the intake form link',
      });
    },
  });

  return { sendIntakeFormMutation };
}
