import RadioYN from '../../../components/RadioYN';
import { MedicalHistoryInfo } from '../types/patient-profile';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import Typography from '../../../components/Typography';
import {
  AllergyDetails,
  MedicationDetails,
} from '@aster/app/core/shared/dtos/patient';
import PatientAllergies from './PatientAllergies';
import PatientMedications from './PatientMedications';

type MedicalHistoryProps = {
  medicalHistory: MedicalHistoryInfo;
  readonly: boolean;
  handleInfo: (event: any) => void;
  handleOption: (property: string, value: any) => void;
  handleCheckboxes: (values: any, property: string) => void;
  handleAllergies: (allergyList: AllergyDetails[]) => void;
  handleMedications: (medications: MedicationDetails[]) => void;
};

type Item = {
  value: string;
  checked: boolean;
  type?: string;
};

const MedicalHistoryInformation = (props: MedicalHistoryProps) => {
  const {
    hasChronicMedicalConditions,
    chronicMedicalConditions,
    hasCurrentMentalHealthProblemOrDiagnosis,
    currentMentalHealthProblemOrDiagnosisDetails,
    hasBloodBorneIllness,
    bloodBorneIllnessDetails,
    hasPreviousAbdominalSurgery,
    abdominalSurgeryDetails,
    isCurrentlyTakingMedicationsOrSuplements,
    currentMedicationsOrSupplementsList,
    hasAllergies,
    allergyList,
    hasFamilyMedicalConditions,
    familyMedicalConditions,
  } = props.medicalHistory;

  const handleInfo = props.handleInfo;
  const handleOption = props.handleOption;
  const handleCheckboxes = props.handleCheckboxes;
  const handleAllergies = props.handleAllergies;
  const handleMedication = props.handleMedications;

  const handleChronic = (value: string) => {
    const updatedItems = chronicMedicalConditions?.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'chronicMedicalConditions');
  };

  const handleMental = (value: string) => {
    const updatedItems = currentMentalHealthProblemOrDiagnosisDetails?.map(
      (item: Item) =>
        item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(
      updatedItems,
      'currentMentalHealthProblemOrDiagnosisDetails'
    );
  };

  const handleBlood = (value: string) => {
    const updatedItems = bloodBorneIllnessDetails?.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'bloodBorneIllnessDetails');
  };

  const handleFamily = (value: string) => {
    const updatedItems = familyMedicalConditions?.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'familyMedicalConditions');
  };

  return (
    <div className="flex flex-col w-full pb-10">
      <Typography
        text="Medical History"
        variant="h5"
        customClass="font-semibold mb-5"
      ></Typography>
      <RadioYN
        classes="mb-5"
        disabled={props.readonly}
        value={hasChronicMedicalConditions}
        field="chronicMedicalConditions"
        question="Do you have any current or previous chronic medical conditions?"
        handleOption={(event: any) =>
          handleOption(
            'hasChronicMedicalConditions',
            event.target.value === 'true'
          )
        }
        handleInfo={handleInfo}
        inforWidth="300px"
        hide
      />
      {hasChronicMedicalConditions && (
        <div className="w-full h-fit bg-grayCard rounded-[10px] px-3 flex flex-col mt-[-10px] mb-2">
          <div className="container w-fit pb-10">
            <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 md:gap-10">
              <div className="my-4">
                {/* Endo */}
                <Typography
                  text="Endocrine"
                  variant="body"
                  customClass="font-semibold"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(0, 4)}
                />
                {/* Reproductive */}
                <Typography
                  text="Reproductive Health"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(4, 6)}
                />
                {/* Respiratory */}
                <Typography
                  text="Respiratory"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(6, 7)}
                />
              </div>

              <div className="my-4">
                {/* Gastro */}
                <Typography
                  text="Gastrointestinal"
                  variant="body"
                  customClass="font-semibold"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(7, 9)}
                />
                {/* Cardiovascular */}
                <Typography
                  text="Cardiovascular"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(9, 10)}
                />
                {/* Neurological */}
                <Typography
                  text="Neurological"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(10, 12)}
                />
              </div>

              <div className="my-4">
                {/* Musculoskeletal */}
                <Typography
                  text="Musculoskeletal"
                  variant="body"
                  customClass="font-semibold"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(12, 14)}
                />
                {/* Autoimmune */}
                <Typography
                  text="Autoimmune"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(14, 16)}
                />
                {/* Renal Conditions */}
                <Typography
                  text="Renal Conditions"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleChronic}
                  items={chronicMedicalConditions.slice(16, 17)}
                />
                {/* Other conditions */}
                <Typography
                  text="Other Conditions"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  handleCheckboxChange={handleChronic}
                  disabled={props.readonly}
                  items={chronicMedicalConditions.slice(17, 18)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <RadioYN
        classes="mb-5"
        disabled={props.readonly}
        value={hasCurrentMentalHealthProblemOrDiagnosis}
        field="currentMentalHealthProblemOrDiagnosisDetails"
        question="Do you have any current mental health problems or diagnoses?"
        handleOption={(event: any) =>
          handleOption(
            'hasCurrentMentalHealthProblemOrDiagnosis',
            event.target.value === 'true'
          )
        }
        handleInfo={handleInfo}
        inforWidth="300px"
        hide
      />
      {hasCurrentMentalHealthProblemOrDiagnosis && (
        <div className="w-full h-fit bg-grayCard rounded-[10px] px-3 flex flex-col mt-[-10px] mb-2">
          <div className="container w-fit pb-10">
            <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 md:gap-10">
              <div className="my-4">
                {/* Mood */}
                <Typography
                  text="Mood Disorders"
                  variant="body"
                  customClass="font-semibold"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    0,
                    2
                  )}
                />
                {/* Anxiety */}
                <Typography
                  text="Anxiety Disorders"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    2,
                    6
                  )}
                />

                {/* Trauma */}
                <Typography
                  text="Trauma and Stressor-Related"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    6,
                    9
                  )}
                />

                {/* Sleep */}
                <Typography
                  text="Sleep-Wake Disorders"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    9,
                    11
                  )}
                />

                {/* Substance */}
                <Typography
                  text="Substance-Related"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    11,
                    13
                  )}
                />
              </div>

              <div className="my-4">
                {/* Obsessive-Compulsive and Related */}
                <Typography
                  text="Obsessive-Compulsive and Related"
                  variant="body"
                  customClass="font-semibold"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    13,
                    16
                  )}
                />
                {/* Eating Disorders*/}
                <Typography
                  text="Eating Disorders"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    16,
                    19
                  )}
                />

                {/* Psychotic Disorders */}
                <Typography
                  text="Psychotic Disorders"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    19,
                    21
                  )}
                />

                {/* Neurodevelopmental */}
                <Typography
                  text="Neurodevelopmental"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    21,
                    23
                  )}
                />

                {/* Personality Disorders */}
                <Typography
                  text="Personality Disorders"
                  variant="body"
                  customClass="font-semibold mt-4"
                ></Typography>
                <MultipleCheckboxes
                  disabled={props.readonly}
                  handleCheckboxChange={handleMental}
                  items={currentMentalHealthProblemOrDiagnosisDetails.slice(
                    23,
                    26
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <RadioYN
        value={hasBloodBorneIllness}
        disabled={props.readonly}
        field="bloodBorneIllnessDetails"
        question="Do you have any blood borne illnesses, such as HIV/Hepatitis?"
        handleOption={(event: any) =>
          handleOption('hasBloodBorneIllness', event.target.value === 'true')
        }
        handleInfo={handleInfo}
        inforWidth="300px"
        hide
      />
      {hasBloodBorneIllness && (
        <MultipleCheckboxes
          handleCheckboxChange={handleBlood}
          disabled={props.readonly}
          items={bloodBorneIllnessDetails}
        />
      )}
      <RadioYN
        classes="my-5"
        value={hasPreviousAbdominalSurgery}
        field="abdominalSurgeryDetails"
        info={abdominalSurgeryDetails}
        disabled={props.readonly}
        question="Have you had any previous abdominal surgeries?"
        handleOption={(event: any) =>
          handleOption(
            'hasPreviousAbdominalSurgery',
            event.target.value === 'true'
          )
        }
        handleInfo={handleInfo}
        inforWidth="300px"
      />

      <div className="mb-5">
        <RadioYN
          value={isCurrentlyTakingMedicationsOrSuplements}
          field="currentMedicationsOrSupplements"
          disabled={props.readonly}
          question="Are you currently taking any prescribed or OTC medications or supplements?"
          handleOption={(event: any) =>
            handleOption(
              'isCurrentlyTakingMedicationsOrSuplements',
              event.target.value === 'true'
            )
          }
          inforWidth="300px"
          hide
        />
        {isCurrentlyTakingMedicationsOrSuplements && (
          <PatientMedications
            readonly={props.readonly}
            medicationList={currentMedicationsOrSupplementsList}
            setMedications={handleMedication}
          />
        )}
      </div>

      <div className="mb-5">
        <RadioYN
          value={hasAllergies}
          question="Do you have any allergies?"
          handleOption={(event: any) =>
            handleOption('hasAllergies', event.target.value === 'true')
          }
          inforWidth="300px"
          disabled={props.readonly}
          hide
        />
        {hasAllergies && (
          <PatientAllergies
            readonly={props.readonly}
            allergyList={allergyList}
            setAllergies={handleAllergies}
          />
        )}
      </div>
      <RadioYN
        value={hasFamilyMedicalConditions}
        question="Do you have any medical problems in your family, such as heart conditions, stroke, cancers, hypertension, diabetes, bleeding disorders, mental health problems?"
        handleOption={(event: any) =>
          handleOption(
            'hasFamilyMedicalConditions',
            event.target.value === 'true'
          )
        }
        handleInfo={handleInfo}
        disabled={props.readonly}
        inforWidth="300px"
        hide
      />
      {hasFamilyMedicalConditions && (
        <MultipleCheckboxes
          disabled={props.readonly}
          handleCheckboxChange={handleFamily}
          items={familyMedicalConditions}
          separateInHalf
        />
      )}
    </div>
  );
};

export default MedicalHistoryInformation;
