import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import {
  LabsOrderDTO,
  ResultsInterpretation,
} from '@aster/app/core/shared/dtos/labs';

import ButtonType from '../../../../../components/Button';
import Typography from '../../../../../components/Typography';
import { cn } from '@aster/client/utils/cn';

export type LabOrdersProps = {
  orders?: LabsOrderDTO[];
};

export const StatusTag = ({ status }: { status: LabsOrderDTO['status'] }) => {
  const classNames = cn(
    'text-[13px] bg-black bg-opacity-[0.08] px-1 py-[3px] rounded-full inline-flex'
  );
  const statusDisplayText =
    {
      sample_with_lab: 'Sample With Lab',
      failed: 'Failed',
      collecting_sample: 'Collecting Sample',
      cancelled: 'Cancelled',
      completed: 'Completed',
      received: 'Received',
      manually_uploaded: 'Manually Uploaded',
    }[status] ?? 'N/A';

  return (
    <span className={classNames}>
      {status === 'completed' && (
        <span className="bg-black bg-opacity-[0.31] text-white aspect-square inline-flex w-[18px] h-[18px] items-center justify-center rounded-full">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      )}
      <span className="px-1.5 h-[18px] content-center">
        {statusDisplayText}
      </span>
    </span>
  );
};

export const ResultTag = ({
  results,
}: {
  results: LabsOrderDTO['results'];
}) => {
  const interpretation = results?.metadata?.interpretation ?? undefined;

  const classNames = cn(
    'text-[13px] bg-grayBlock px-1 py-[3px] rounded-full inline-flex text-white',
    {
      'text-primary': !interpretation,
      'bg-[#16a34a]': interpretation === 'normal',
      'bg-[#EAB308]': interpretation === 'abnormal',
      'bg-[#DC2626]': interpretation === 'critical',
    }
  );
  const resultDisplayText =
    {
      normal: 'Normal',
      abnormal: 'Abnormal',
      critical: 'Critical',
    }[interpretation as ResultsInterpretation] ?? 'N/A';

  return (
    <span className={classNames}>
      <span className="px-1.5 h-[18px] content-center">
        {resultDisplayText}
      </span>
    </span>
  );
};

export const ResultCard = ({ order }: { order: LabsOrderDTO }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex-row space-y-2">
        <Typography variant="body" customClass="text-[16px] font-semibold">
          {order.labTest.name}
        </Typography>
        <div className="flex items-center gap-2">
          <StatusTag status={order.status} />
          {order.results && <ResultTag results={order.results} />}
        </div>
      </div>
      {order.results && (
        <Link to={`/patientProfile/${order.patientID}?tab=5&order=${order.id}`}>
          <ButtonType variant="outlined" text="View Results" notRounded />
        </Link>
      )}
    </div>
  );
};

export const LabOrders = ({ orders = [] }: LabOrdersProps) => {
  return (
    <div className="border border-grayBorder p-5 rounded-[16px] relative">
      {orders.map((order, i) => (
        <>
          <ResultCard order={order} />
          {i < orders.length - 1 && (
            <hr className="border-[0.5px] border-grayBorder my-6" />
          )}
        </>
      ))}
    </div>
  );
};
