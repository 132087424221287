import {
  LumberjackLevel,
  createErrorLogBuilder,
  createWarningLogBuilder,
  createInfoLogBuilder,
  createLumberjack,
  createLumberjackConfig,
  LumberjackLogPayload,
  LumberjackConfig,
  LumberjackDriver,
  Lumberjack,
} from '@naxodev/lumberjack';
import { LumberjackConsoleDriver } from '@naxodev/lumberjack/console-driver/src/index';

const drivers = [
  new LumberjackConsoleDriver({
    levels: [LumberjackLevel.Verbose],
    useStructuredLogs: true,
  }),
];

const config = createLumberjackConfig(false, {});
let lumberjack: Lumberjack | null = null;

export const getLumberjack = <
  TPayload extends LumberjackLogPayload | void = void
>(): Lumberjack<TPayload> => {
  if (!lumberjack) {
    lumberjack = createLumberjack<TPayload>({
      drivers: drivers as LumberjackDriver<TPayload>[],
      config: config as LumberjackConfig<TPayload>,
    }) as Lumberjack;
  }

  return lumberjack as Lumberjack<TPayload>;
};

export const createScopedInfoLog = (scope: string) => (message: string) =>
  createInfoLogBuilder()(message).withScope(scope).build();

export const createScopedInfoLogWithPayload =
  (scope: string) => (message: string) =>
    createInfoLogBuilder<LumberjackLogPayload>()(message).withScope(scope);

export const createScopedErrorLog = (scope: string) => (message: string) =>
  createErrorLogBuilder<{
    error: Error | Record<string, unknown>;
    metadata?: Record<string, unknown>;
  }>()(message).withScope(scope);

export const createScopedWarnLog = (scope: string) => (message: string) =>
  createWarningLogBuilder()(message).withScope(scope);
