import Typography from '../../../components/Typography';
import ButtonType from '../../../components/Button';
import { useParams } from 'react-router-dom';
import { useCreateLabsOrderMutation } from '../mutations/create-labs-order.mutation';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { names } from '@aster/shared/utils';
import { AnimatePresence, motion } from 'framer-motion';
import Divider from '@mui/material/Divider';
import { AxiosError } from 'axios';
import FormHelperText from '@mui/material/FormHelperText';
import { useLabsStore } from '../store/labs.store';
import { useSelectedLabTestQuestions } from '../queries/get-lab-test-questions.query';
import { useEffect } from 'react';

export const OrderCheckout = () => {
  const { patient } = useParams();
  const selectedLabTest = useLabsStore((state) => state.selectedLabTest);
  const { createLabsOrderMutation } = useCreateLabsOrderMutation(
    (newOrderID) => {
      closeCreateOrderDialog();
      addNewOrderID(newOrderID);
    }
  );
  const isCheckoutQuestionsVisible = useLabsStore(
    (state) => state.isCheckoutQuestionsVisible
  );
  const { labTestQuestions, isLoadingQuestions } = useSelectedLabTestQuestions(
    selectedLabTest?.id
  );
  const answers = useLabsStore((state) => state.answers);
  const closeCreateOrderDialog = useLabsStore(
    (state) => state.closeCreateOrderDialog
  );
  const addNewOrderID = useLabsStore((state) => state.addNewOrderID);
  const showCheckoutQuestions = useLabsStore(
    (state) => state.showCheckoutQuestions
  );
  const initializeAsnwers = useLabsStore((state) => state.initializeAsnwers);
  const getError = () => {
    if (createLabsOrderMutation.error instanceof AxiosError) {
      const data = createLabsOrderMutation.error.response?.data;
      return typeof data === 'string' ? data : data?.message || '';
    }
    return '';
  };

  const canMoveToNextStep =
    labTestQuestions?.length && !isCheckoutQuestionsVisible;
  const allQuestionsAnswered =
    answers.size === labTestQuestions?.length &&
    Array.from(answers.values()).every((a) => a.answer);

  const canSubmit =
    selectedLabTest &&
    !isLoadingQuestions &&
    (canMoveToNextStep || !labTestQuestions?.length || allQuestionsAnswered);

  useEffect(() => {
    if (labTestQuestions) {
      initializeAsnwers(labTestQuestions);
    }
  }, [initializeAsnwers, labTestQuestions]);

  return (
    <div className="flex flex-col">
      {selectedLabTest && (
        <AnimatePresence>
          <OrderSummary />
        </AnimatePresence>
      )}
      <div className="flex justify-between">
        <Typography
          text="Total"
          variant="bodySmall"
          customClass="font-semibold text-black"
        />
        <Typography
          text={`$${(selectedLabTest?.price || 0).toFixed(2)}`}
          variant="bodySmall"
          customClass="font-semibold text-black"
        />
      </div>
      <Typography
        text="Fees may apply"
        variant="meta"
        customClass="ml-auto text-asterGray"
      />
      <ButtonType
        loading={
          isLoadingQuestions || createLabsOrderMutation.status === 'pending'
        }
        onClick={async () => {
          if (labTestQuestions?.length && !isCheckoutQuestionsVisible) {
            showCheckoutQuestions();
          } else if (selectedLabTest && patient) {
            await createLabsOrderMutation.mutateAsync({
              patientID: patient,
              labTestID: selectedLabTest?.id,
              answers: answers.size ? Array.from(answers.values()) : undefined,
              isManualOrder: false,
            });
          }
        }}
        disabled={!canSubmit}
        text={
          labTestQuestions?.length && !isCheckoutQuestionsVisible
            ? 'Next step'
            : 'Submit order'
        }
        variant="contained"
        className="mt-5"
      />
      <FormHelperText className="mx-auto" error={true}>
        {getError()}
      </FormHelperText>
      <Typography
        text="The client will receive an email & SMS with instructions regarding their order."
        variant="meta"
        customClass="ml-auto text-asterGray max-w-[42ch] text-center mx-auto mt-2"
      />
    </div>
  );
};

const OrderSummary = () => {
  const selectedLabTest = useLabsStore((state) => state.selectedLabTest);
  const selectLabTest = useLabsStore((state) => state.selectLabTest);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Card
        elevation={0}
        className={'rounded-[0.625rem] border border-grayBackground mb-4'}
      >
        <CardContent className="flex flex-col px-0 !py-3">
          <div className="flex justify-between px-3">
            <Typography
              variant="bodySmall"
              text={selectedLabTest?.name ?? ''}
              customClass="font-semibold text-black overflow-ellipsis max-w-[80%] overflow-hidden whitespace-nowrap"
            />
            <Typography
              variant="meta"
              text={`$${selectedLabTest?.price.toFixed(2)}`}
              customClass="font-semibold text-black"
            />
          </div>

          <div className="flex justify-between items-center px-3">
            <Typography
              variant="meta"
              text={
                selectedLabTest?.sampleType
                  ? `${names(selectedLabTest?.sampleType).sentenceCase} sample`
                  : ''
              }
              customClass="text-asterGray"
            />
            <ButtonType
              onClick={() => selectLabTest(null)}
              variant="text"
              text="Remove"
              className="text-secondary text-p10 p-0 h-fit font-semibold"
            />
          </div>
          <Divider className="my-2 border-grayBackground" />

          <div className="flex justify-between items-center px-3">
            <Typography
              variant="meta"
              text="Delivery Method"
              customClass="text-asterGray"
            />
            <Typography
              variant="meta"
              text={`Direct via ${selectedLabTest?.labName}`}
              customClass="text-asterGray font-semibold"
            />
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};
