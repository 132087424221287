import { cn } from '@aster/client/utils';

export type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'p-sm' | 'p-xs';

type TypographyProps = {
  variant: Variant;
  className?: string;
  children?: React.ReactNode;
};

const baseClasses = 'text-gray-900';

export function Typography({ variant, children, className }: TypographyProps) {
  switch (variant) {
    case 'h1':
      return (
        <h1
          className={cn(
            baseClasses,
            'text-4xl sm:text-5xl font-semibold',
            className
          )}
        >
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={cn(baseClasses, 'text-3xl font-semibold', className)}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={cn(baseClasses, 'text-2xl font-semibold', className)}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={cn(baseClasses, 'text-xl font-semibold', className)}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={cn(baseClasses, 'font-semibold', className)}>
          {children}
        </h5>
      );
    case 'p':
      return (
        <p className={cn('text-gray-700 leading-[160%]', className)}>
          {children}
        </p>
      );
    case 'p-sm':
      return (
        <p className={cn('text-sm text-gray-700 leading-[160%]', className)}>
          {children}
        </p>
      );
    case 'p-xs':
      return (
        <p className={cn('text-xs text-gray-500', className)}>{children}</p>
      );
  }
}
