import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import { colors } from '../../theme';
import ButtonType from '../../components/Button';
import axios from '../../app/axiosConfig';
import { useMutation } from '@tanstack/react-query';
import EncountersList from './forms/EncountersList';
import ConfirmationModal from '../../components/ConfirmationModal';
import { usePatientForm } from './hooks/usePatientForm';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import PatientDocuments from '../documents/PatientDocuments';
import ProfileTab from './profileTabs/ProfileTab';
import HealthTab from './profileTabs/HealthTab';
import { HorizontalLinkTab } from '../../components/Tabs';
import StyledHorizontalTab from './StyledHorizontalTab';
import Typography from '../../components/Typography';
import PatientProfileForEncounterHeader from '../notes/sections/PatientProfileForEncounterHeader';
import PreferencesTab from './profileTabs/PreferencesTab';
import {
  ExitToAppOutlined,
  FileUploadOutlined,
  FilterAltOutlined,
} from '@mui/icons-material';
import PatientOptionsMenu from './PatientOptionsMenu';
import ConfirmDeletePatientModal from './ConfirmDeletePatientModal';
import PatientLabs from '../labs/PatientLabs';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import styled from '@mui/system/styled';
import Select from '@mui/material/Select';
import { usePatientProfileQuery } from '../notes/queries/patient-profile.query';
import Prescriptions from '../dosespot/Prescriptions';
import AddPatientForm from '../dosespot/AddPatientForm';
import { useDosespot } from '../dosespot/DosespotProvider';
import { Ledger, LedgerHandle } from '../payments/Ledger';
import { useGetEncounters } from './queries/use-get-encounters.query';
import { useVitalsData } from './queries/use-get-vitals.query';
import { downloadEncountersPdf } from '../notes/sections/utils';
import Download from '../../assets/icons/Download';
import { usePatientInfoQuery } from './queries/patient-info.query';
import type { PatientProfile as PatientProfileType } from './types/patient-profile';
import { useUpdatePatientMutation } from './mutations/update-patient.mutation';
import { useDeletePatientMutation } from './mutations/delete-patient.mutation';
import {
  createMutationScopeID,
  MutationScope,
} from '../../mutations/utils/create-mutation-scope-id';
import { useLedgerRecordsQuery } from '../payments/queries/use-ledger-records-query';
import { useAuth } from '../../authentication/AuthProvider';
import OverviewTab from './profileTabs/OverviewTab/OverviewTab';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { isPatientReadonly } from './utils/is-patient-readonly';

const createCounter = () => {
  let count = 0;

  return {
    next: () => count++,
  };
};

const counter = createCounter();

const tabIndexes = {
  overview: counter.next(),
  profile: counter.next(),
  healthRecords: counter.next(),
  patientRx: counter.next(),
  encounters: counter.next(),
  labs: counter.next(),
  documents: counter.next(),
  preferences: counter.next(),
  payments: counter.next(),
};

const PatientProfile = () => {
  const navigate = useNavigate();
  const { isAsterAdmin } = useAuth();
  const { patient } = useParams();
  const [value, setValue] = useState(0);
  const [type, setType] = useState('');
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDeletePatientModal, setOpenDeletePatientModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const ledgerRef = useRef<LedgerHandle>(null);

  useEffect(() => {
    if (searchParams.get('tab')) {
      return setValue(parseInt(searchParams.get('tab') as string));
    }
    return setValue(0);
  }, [searchParams]);
  const [isSelectingEncounters, setIsSelectingEncounters] = useState(false);
  const [selectedEncounterIds, setSelectedEncounterIds] = useState<string[]>(
    []
  );

  const dosespotContext = useDosespot();

  const toggleUploadModal = (value: boolean | undefined) => {
    if (value !== undefined) {
      setOpenUploadModal(value);
      return;
    }
    setOpenUploadModal(!openUploadModal);
  };

  const {
    data: encounters,
    isLoading: areEncountersLoading,
    refetch: refetchEncounters,
  } = useGetEncounters(patient ?? '');

  const { vitals } = useVitalsData({
    patientID: patient ?? '',
  });

  const selectedEncounters = useMemo(() => {
    return encounters?.filter((encounter) =>
      selectedEncounterIds.includes(encounter.id)
    );
  }, [encounters, selectedEncounterIds]);

  const { patientProfile } = usePatientProfileQuery(patient as string);

  const { updatePatientMutation } = useUpdatePatientMutation(
    patient as string,
    () => {
      setIsDirty(false);
      void refetchPatient();
    }
  );

  const { patientInfo, isPatientLoading, refetchPatient } =
    usePatientInfoQuery(patient);
  const { deletePatientMutation } = useDeletePatientMutation(() => {
    navigate('/patients', { state: { deletedPatient: true } });
  });

  const { ledgerRecords } = useLedgerRecordsQuery({
    patientID: patient,
  });

  const {
    isDirty,
    setIsDirty,
    acccessInfo,
    commuInformation,
    additionalInformation,
    socialInformation,
    lifestyleInformation,
    medicalHistory,
    obstetricInformation,
    pregnancyInformation,
    gynHis,
    referralInfo,
    preferredPharmacyInfo,
    contactListInfo,
    insurance,
    relationship,
    contactInformation,
    personalInformation,
    handleSocialInfo,
    handleGynDate,
    handleBirthday,
    handleGynHisInfo,
    handleContactInfo,
    handleContactInfoBulk,
    handleGynHisOption,
    handlePersonalInfo,
    handlePregancyInfo,
    handlePregnancyCheck,
    handleReferralInfo,
    handleInsuranceInfo,
    handleAdditionalInfo,
    handleLifestylelInfo,
    handlePregnancyDates,
    handleCheckboxesCommu,
    handleCheckGynHisInfo,
    handleContactListInfo,
    handleLifestyleOption,
    handleCheckboxesAccess,
    handleRelationshipInfo,
    handleSocialInfoOption,
    handleCheckboxesSocialInfo,
    handleCheckboxesGynHist,
    handleCheckboxesMedical,
    handleCheckInsuranceInfo,
    handleMedicalHistoryInfo,
    handleMedicalHistoryAllergies,
    handleMedicalHistoryCurrentMedications,
    handlePersonalInfoSelect,
    handlePregancyInfoOption,
    handleReferralInfoSelect,
    handlePreferredPharmacyInfo,
    handleCheckboxesLifestyle,
    handleMedicalHistoryOption,
    handleContactListInfoSelect,
    handlePregnanciesInformation,
    saveInfo,
  } = usePatientForm({
    data: patientInfo,
    patientID: patient,
    updatePatientMutation: updatePatientMutation,
  });

  // Block navigating elsewhere when form is dirty
  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      blocker?.proceed?.();
      setIsDirty(false); // Reset only if the user confirms leaving
    } else {
      blocker?.reset?.();
    }
  };

  const addNoteMutation = async (info: any) => axios.post(`encounters/`, info);

  const addNote = useMutation({
    mutationKey: ['addNote'],
    mutationFn: addNoteMutation,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT, patient),
    },
    onSuccess: (response) => {
      const encounterId = response.data.id;
      if (encounterId !== undefined) {
        navigate(`/encounter/${patient}/${encounterId}`);
      }
    },
    onSettled: () => {
      setType('');
    },
  });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: newValue.toString() });
  };

  if (isPatientLoading) {
    return (
      <div className="flex flex-1 flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  const handleChangeType = (event: SelectChangeEvent) => {
    const typeValue = event.target.value as string;
    setType(typeValue);
    const info = {
      patientID: patient,
      templateType: typeValue,
    };
    addNote.mutate(info);
  };

  const Options = [
    { value: 'basic', text: 'Basic' },
    { value: 'birthflow', text: 'Labor & Birth' },
    { value: 'prenatal', text: 'Prenatal' },
    { value: 'soap', text: 'SOAP' },
  ] as const;

  const CustomSelect = styled(Select)({
    '& .MuiSelect-outlined': {
      borderRadius: '20px',
      backgroundColor: 'transparent',
      borderColor: colors.primary,
      '&:focus': {
        borderRadius: '20px',
        backgroundColor: 'transparent',
      },
    },
  });

  const SelectTypeComponent = () => {
    return addNote.status === 'pending' ? (
      <CircularProgress size={20} />
    ) : (
      <FormControl>
        <CustomSelect
          placeholder="Create Note"
          className="rounded-full bg-transparent text-body font-semibold border-1"
          defaultValue="Create Note"
          variant="outlined"
          value={type}
          displayEmpty
          onChange={
            handleChangeType as (
              event: SelectChangeEvent<unknown>,
              child: ReactNode
            ) => void
          }
          inputProps={{ 'aria-label': 'Without label' }}
          style={{ height: 40, width: 150, borderRadius: 20 }}
        >
          <MenuItem value="" disabled>
            Create Note
          </MenuItem>
          {Options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    );
  };

  const HeaderButtons = () => {
    return (
      <div className="flex items-center gap-2">
        {value === tabIndexes.encounters && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isSelectingEncounters ? (
              <div className="flex gap-x-2">
                {selectedEncounterIds.length > 0 && (
                  <div className="flex items-center justify-center rounded-[8px] py-1 px-4 bg-grayBackground">
                    <Typography
                      variant="bodySmall"
                      text={`${selectedEncounterIds.length} encounters selected`}
                      customClass="font-semibold"
                    />
                  </div>
                )}
                <ButtonType
                  classes="max-h-8 rounded-[8px] text-bodySmall text-dataGridGray py-1 px-4"
                  variant="outlined"
                  text="Cancel"
                  onClick={() => setIsSelectingEncounters(false)}
                />
                <ButtonType
                  classes={`max-h-8 rounded-[8px] text-bodySmall py-1 px-4 ${
                    selectedEncounterIds.length > 0
                      ? 'bg-black text-white'
                      : 'text-gray-500'
                  } hover:bg-black hover:text-white`}
                  disabled={selectedEncounterIds.length === 0}
                  variant="outlined"
                  text={`Download ${
                    selectedEncounterIds.length > 0
                      ? '(' + selectedEncounterIds.length + ')'
                      : ''
                  }`}
                  onClick={async () => {
                    await downloadEncountersPdf({
                      encounters: selectedEncounters,
                      patientProfile,
                      vitals,
                    });
                    setIsSelectingEncounters(false);
                  }}
                />
              </div>
            ) : (
              <ButtonType
                variant="outlined"
                icon={true}
                specificIcon={<Download className="mr-2" />}
                text="Download Encounters"
                classes="max-h-8 rounded-[8px] text-bodySmall text-gray-500 py-1 px-4"
                onClick={() => setIsSelectingEncounters(true)}
              />
            )}
          </>
        )}
        {patientInfo?.status !== 'archived' && (
          <PatientOptionsMenu
            openDeletePatientModal={() => setOpenDeletePatientModal(true)}
          />
        )}
        {value === tabIndexes.encounters &&
          patientInfo?.status !== 'archived' && <SelectTypeComponent />}
        {value === tabIndexes.documents &&
          patientInfo?.status !== 'archived' && (
            <ButtonType
              variant="contained"
              text={'Upload Files'}
              onClick={() => setOpenUploadModal(true)}
              icon={true}
              specificIcon={
                <FileUploadOutlined htmlColor={colors.white} className="mr-2" />
              }
            />
          )}
        {value === tabIndexes.payments && (
          <>
            {isAsterAdmin && (
              <>
                <ButtonType
                  variant="outlined"
                  text="Add Filter"
                  onClick={() => ledgerRef.current?.addFilter()}
                  icon
                  specificIcon={<FilterAltOutlined />}
                  notRounded
                />
                <ButtonType
                  variant="outlined"
                  text="Export"
                  onClick={() => ledgerRef.current?.export()}
                  icon
                  specificIcon={<ExitToAppOutlined />}
                  notRounded
                />
              </>
            )}
            <ButtonType
              disabled={isPatientReadonly(patientInfo)}
              variant="contained"
              text="Add charge/payment"
              onClick={() => ledgerRef.current?.addRecord()}
            />
          </>
        )}
        {[
          tabIndexes.overview,
          tabIndexes.profile,
          tabIndexes.healthRecords,
          tabIndexes.patientRx,
          tabIndexes.labs,
          tabIndexes.preferences,
        ].includes(value) &&
          !isPatientReadonly(patientInfo) && (
            <div className="flex gap-2">
              <ButtonType
                loading={updatePatientMutation.status === 'pending'}
                disabled={updatePatientMutation.status === 'pending'}
                variant="contained"
                text="Save"
                onClick={saveInfo}
              />
            </div>
          )}
      </div>
    );
  };

  if (patientInfo) {
    return (
      <div className="grid grid-rows-fill-last h-full overflow-auto">
        <div className="flex justify-between w-full bg-white p-6 border-grayLight">
          <div className="flex w-full justify-between flex-wrap gap-2">
            {patientProfile && (
              <PatientProfileForEncounterHeader
                name={patientProfile.name}
                mrn={patientProfile.mrn}
                calculatedDueDate={patientProfile.calculatedDueDate}
                gestationalAge={patientProfile.gestationalAge}
                gtpal={patientProfile.gtpal}
                age={patientProfile.age}
                dateOfBirth={patientProfile.dateOfBirth}
                problemList={patientProfile.problemList}
                isPregnant={patientProfile.isPregnant}
              />
            )}
          </div>
        </div>

        <div className="full-w-container py-0 mb-4 flex flex-col h-full overflow-y-auto">
          <div className="flex flex-wrap justify-between mb-3 gap-2">
            <div className="shrink-0 flex bg-grayBackground p-1 rounded-xl w-fit">
              <Tabs
                className="flex items-center h-auto"
                allowScrollButtonsMobile
                scrollButtons
                variant="standard"
                orientation="horizontal"
                value={value}
                onChange={handleChangeTab}
                classes={{
                  flexContainer: 'gap-x-1',
                }}
                style={{ minHeight: 'auto' }}
              >
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Overview" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Profile" />}
                />
                <StyledHorizontalTab
                  label={
                    <Typography variant="bodyMedium" text="Health Records" />
                  }
                />
                <StyledHorizontalTab
                  disabled={patientInfo.status === 'archived'}
                  label={<Typography variant="bodyMedium" text="Patient Rx" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Encounters" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium">Labs</Typography>}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Documents" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Preferences" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Payments" />}
                />
              </Tabs>
            </div>
            <HeaderButtons />
          </div>

          <Divider className="border-gray-200 mb-4" />

          <HorizontalLinkTab value={value} index={tabIndexes.overview}>
            <OverviewTab
              patient={patientProfile as PatientProfileForEncounterDTO}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.profile}>
            <ProfileTab
              readonly={isPatientReadonly(patientInfo)}
              personalInformation={personalInformation}
              handlePersonalInfo={handlePersonalInfo}
              handleBirthday={handleBirthday}
              handlePersonalInfoSelect={handlePersonalInfoSelect}
              contactInformation={contactInformation}
              handleContactInfo={handleContactInfo}
              relationship={relationship}
              handleRelationshipInfo={handleRelationshipInfo}
              insurance={insurance}
              handleInsuranceInfo={handleInsuranceInfo}
              handleCheckInsuranceInfo={handleCheckInsuranceInfo}
              contactListInfo={contactListInfo}
              preferredPharmacyInfo={preferredPharmacyInfo}
              referralInfo={referralInfo}
              handleReferralInfo={handleReferralInfo}
              handleReferralInfoSelect={handleReferralInfoSelect}
              handlePreferredPharmacyInfo={handlePreferredPharmacyInfo}
              handleContactListInfo={handleContactListInfo}
              handleContactListInfoSelect={handleContactListInfoSelect}
              handleContactInfoBulk={handleContactInfoBulk}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.healthRecords}>
            <HealthTab
              readonly={isPatientReadonly(patientInfo)}
              gynHis={gynHis}
              handleGynHisOption={handleGynHisOption}
              handleGynHisInfo={handleGynHisInfo}
              handleCheckGynHisInfo={handleCheckGynHisInfo}
              handleCheckboxesGynHist={handleCheckboxesGynHist}
              handleGynDate={handleGynDate}
              pregnancyInformation={pregnancyInformation}
              handlePregancyInfo={handlePregancyInfo}
              handlePregnancyCheck={handlePregnancyCheck}
              handlePregancyInfoOption={handlePregancyInfoOption}
              handlePregnancyDates={handlePregnancyDates}
              obstetricInformation={obstetricInformation}
              handlePregnanciesInformation={handlePregnanciesInformation}
              medicalHistory={medicalHistory}
              handleMedicalHistoryOption={handleMedicalHistoryOption}
              handleMedicalHistoryInfo={handleMedicalHistoryInfo}
              handleMedicalHistoryAllergies={handleMedicalHistoryAllergies}
              handleMedicalHistoryCurrentMedications={
                handleMedicalHistoryCurrentMedications
              }
              handleCheckboxesMedical={handleCheckboxesMedical}
              lifestyleInformation={lifestyleInformation}
              handleLifestyleOption={handleLifestyleOption}
              handleLifestylelInfo={handleLifestylelInfo}
              handleCheckboxesLifestyle={handleCheckboxesLifestyle}
              socialInformation={socialInformation}
              handleCheckboxesSocialInfo={handleCheckboxesSocialInfo}
              handleSocialInfoOption={handleSocialInfoOption}
              handleSocialInfo={handleSocialInfo}
              handleAdditionalInfo={handleAdditionalInfo}
              additionalInformation={additionalInformation}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab
            value={value}
            index={tabIndexes.patientRx}
            classes="h-full"
          >
            {!patientInfo?.dosespotPatientId &&
            dosespotContext.dosespotData.client_id &&
            dosespotContext.dosespotData.client_secret &&
            dosespotContext.dosespotData.username &&
            dosespotContext.dosespotData.password ? (
              <AddPatientForm
                patient={
                  patientInfo as unknown as PatientProfileType & { id: string }
                }
                refetchPatient={refetchPatient}
                handlePersonalInfoSelect={handlePersonalInfoSelect}
                handlePersonalInfo={handlePersonalInfo}
                handleBirthday={handleBirthday}
                handleContactInfo={handleContactInfo}
                handleContactInfoSelect={handleContactListInfoSelect}
                saveInfo={saveInfo}
              />
            ) : (
              <Prescriptions
                dosespotPatientId={patientInfo?.dosespotPatientId as string}
              />
            )}
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.encounters}>
            <EncountersList
              encounters={encounters}
              areEncountersLoading={areEncountersLoading}
              refetchEncounters={refetchEncounters}
              isSelectingEncounters={isSelectingEncounters}
              setSelectedEncounterIds={setSelectedEncounterIds}
              patientProfile={patientProfile}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.labs}>
            <PatientLabs />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.documents}>
            <PatientDocuments
              openUploadModal={openUploadModal}
              toggleUploadModal={toggleUploadModal}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.preferences}>
            <PreferencesTab
              readonly={isPatientReadonly(patientInfo)}
              acccessInfo={acccessInfo}
              handleCheckboxesAccess={handleCheckboxesAccess}
              handleCheckboxesCommu={handleCheckboxesCommu}
              commuInformation={commuInformation}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={tabIndexes.payments}>
            <Ledger
              ref={ledgerRef}
              records={ledgerRecords}
              patients={[patientInfo]}
            />
          </HorizontalLinkTab>
        </div>
        <ConfirmationModal
          open={blocker.state === 'blocked'}
          title="Unsaved Changes"
          description={`Are you sure you want to leave? Changes may not be saved.`}
          confirm="Leave"
          dismiss="Cancel"
          handleClose={() => handleClose(false)}
          handleConfirm={() => handleClose(true)}
          handleCancel={() => handleClose(false)}
        />
        <ConfirmDeletePatientModal
          patientID={patient as string}
          open={openDeletePatientModal}
          handleClose={() => setOpenDeletePatientModal(false)}
          handleConfirm={deletePatientMutation}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <CircularProgress />
    </div>
  );
};

export default PatientProfile;
